import { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  Button,
  theme,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
  FAV_ITEMS_KEY,
} from "@project/shared";
import { LineShare, EmailShare } from "../../../organisms";
import { PetContext } from "../../../molecules";
import Mail from "../../../../assets/mail.svg";
import Line from "../../../../assets/line.svg";
import Fav from "../../../../assets/favcolor.svg";
interface IProps {
  petDetails?: any;
}

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 0px 35px;
  & > svg {
    margin-right: 10px;
  }
  margin-top: 10px;
  @media screen and (max-width: 1350px) and (min-width: 768px) {
    padding: 0px 17px;
  }
`;

const LineShareStyled = styled.div`
  margin-left: 10px;
  margin-top: 10px;

  & > button {
    outline: none;
    display: flex;
    align-items: center;
    & > svg {
      margin-right: 10px;
    }
    min-height: 40px;
  }

  ${theme.typography.PetButton};
  padding: 0px 35px;
  color: ${theme.base};
  @media screen and (max-width: 1350px) and (min-width: 768px) {
    padding: 0px 17px;
  }
  border-radius: 20px;
  background: ${theme.line};
`;
const TopButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  @media screen and (max-width: ${theme.size}) {
    justify-content: center;
  }
`;
const PetTopButtons: React.FC<IProps> = ({ petDetails }) => {
  const [favId, setFavId] = useState<string | string[]>("");
  const [isFavItem, setIsFavItem] = useState<boolean>(false);
  const { setFavItemListCount } = useContext(PetContext);
  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    setFavId(router?.query.id);
    const favItems = getItemFromLocalStorage(FAV_ITEMS_KEY) || [];
    const favIds = favItems?.map((item) => item?.id);
    const checkFavItem = favIds?.includes(favId);
    setIsFavItem(checkFavItem);
  }, [favId]);

  const handleFavIconClick = () => {
    const favItems = getItemFromLocalStorage(FAV_ITEMS_KEY) || [];
    const favIds = favItems?.map((item) => item?.id);
    if (favIds.indexOf(favId) === -1) {
      const favItem = {
        id: petDetails?.id,
        name: petDetails?.name,
        image: petDetails?.main_image,
        date: petDetails?.birthday
          ? moment(petDetails?.birthday).format("YYYY")
          : "",
        gender: petDetails?.gender,
      };
      favItems.push(favItem);
      setFavItemListCount(favItems?.length + 1);
      setIsFavItem(true);
      saveItemToLocalStorage(FAV_ITEMS_KEY, favItems);
    } else {
      setIsFavItem(false);
      const filteredItems = favItems?.filter((item) => item?.id != favId);
      saveItemToLocalStorage(FAV_ITEMS_KEY, filteredItems);
      setFavItemListCount(favItems?.length - 1);
    }
  };

  return (
    <TopButtons>
      <StyledButton
        minheight={40}
        typography={theme.typography.PetButton}
        color={theme.primary}
        borderradius={20}
        background={theme.primaryLight}
        icon={<Fav />}
        onClick={handleFavIconClick}
      >
        {isFavItem ? t("Remove from favourites") : t("Add to Favourites")}
      </StyledButton>
      <EmailShare
        subject={`コーワペッツ: ${petDetails.name}`}
        body={`${petDetails.name}の詳細をこちらからご覧頂けます。\n${window.location.href}`}
      >
        <StyledButton
          typography={theme.typography.PetButton}
          color={theme.base}
          borderradius={20}
          background={theme.primary}
          icon={<Mail />}
          minheight={40}
        >
          {t("Send by email")}
        </StyledButton>
      </EmailShare>
      <LineShareStyled>
        <LineShare
          title={`コーワペッツ: ${petDetails.name}`}
          url={window.location.href}
        >
          <Line /> LINEで送る
        </LineShare>
      </LineShareStyled>
    </TopButtons>
  );
};

export { PetTopButtons };
