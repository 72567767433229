import { Button, theme } from "@project/shared";
import React from "react";
import styled from "styled-components";
import { VoiceCard } from "../../../molecules";

const Container = styled.div`
  padding: 98px 0 103px;
  background-color: ${theme.lightGrey};
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1480px;
  margin: auto;
  & button {
    margin: 80px auto 0px;
    font-size: 18px;
    box-shadow: -1.22465e-15px 20px 20px rgba(4, 0, 0, 0.0784314);
    line-height: 27px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    & button {
      width: 100%;
      max-width: 400px;
      min-width: auto;
    }
  }
  @media (max-width: 475px) {
    & button {
      font-size: 16px;
      min-height: 60px;
      margin-top: 60px;
    }
  }
`;

const Heading = styled.p`
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 23px;
  color: ${theme.primary};
  @media (max-width: 475px) {
    margin-bottom: 6px;
  }
`;
const Title = styled.p`
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  color: ${theme.primary};
  margin-bottom: 93px;
  @media (max-width: 475px) {
    margin-bottom: 80px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 29.72%;
  }
  @media (max-width: 1300px) {
    & > div {
      width: 31%;
    }
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    justify-content: space-around;
    flex-wrap: wrap;
    & > div {
      width: 47%;
    }
    & > div:last-child {
      margin-top: 60px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin: auto;
    width: 100%;
    align-items: center;
    & > div {
      width: 100%;
      margin-bottom: 106px;
    }
    & > div:last-child {
      margin-bottom: 0px !important;
    }
  }
  @media (max-width: 475px) {
    & > div {
      margin-bottom: 70px;
    }
  }
`;

const VoiceSection = (props) => {
  return (
    <Container>
      <Wrapper>
        <Heading>VOICE</Heading>
        <Title>お客様の声</Title>
        <CardWrapper>
          {props &&
            props.voiceData &&
            props.voiceData.map((voice: any, index: number) => (
              <VoiceCard
                key={index}
                name={voice.name || voice.pet_name}
                image={voice.image || "/default.png"}
                index={`0${index + 1}`}
                title={voice.title || voice.voice_title}
                description={voice.description || voice.comment}
                voice_title={voice.voiceTitle || voice.voice_title}
              />
            ))}
        </CardWrapper>
        <Button
          href="/voices"
          rounded
          type="primary"
          minheight={60}
          minwidth={300}
        >
          お客様の声をもっと見る
        </Button>
      </Wrapper>
    </Container>
  );
};
export { VoiceSection };
