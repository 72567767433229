import { QueryProps } from "./queryContext";
import React, { useState } from "react";
export const useQuery = (): QueryProps => {
  const [query, setQuery] = useState({
    prefectures: [],
    malls: [],
    services: [],
    keyword: "",
  } as any);

  const AddQuery = React.useCallback((value: any, key: string) => {
    if (key == "add") setQuery(value);
  }, []);

  return {
    query,
    AddQuery,
  };
};
