import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
interface NoticeCardProps {
  date?: string;
  notice?: string;
  isStoreInformation?: boolean;
}
const NoticeContainer = styled.div`
  max-width: 600px;
  padding: 22px 0px 45px 40px;
  background: #ffffff;
  box-shadow: -1.22465e-15px 20px 20px rgba(4, 0, 0, 0.0784314);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const DateWithTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 16px;
`;
const DateTag = styled.strong`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
  margin-bottom: 24px;
`;

const InfoTag = styled.div`
  width: 130px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 5px 23px;
  color: #ffffff;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;
const Notice = styled.strong`
  font-size: 14px;
  line-height: 21px;
  color: #313131;
  margin-top: 6px;
  padding-right: 13px;
`;

const NoticeCard: React.FC<NoticeCardProps> = (props: NoticeCardProps) => {
  const { t } = useTranslation();
  return (
    <NoticeContainer>
      <DateWithTag>
        <DateTag>{t(`${props.date}`)}</DateTag>
        <InfoTag
          style={{
            background: props.isStoreInformation ? "#EB6014" : "#5FB2DA",
          }}
        >
          {props.isStoreInformation ? t(`店舗のお知らせ`) : t("セール情報")}
        </InfoTag>
      </DateWithTag>
      <Notice>{t(`${props.notice}`)}</Notice>
    </NoticeContainer>
  );
};

export { NoticeCard };
