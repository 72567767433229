import { theme } from "@project/shared";
import Image from "next/image";
import NextLink from "next/link";
import React from "react";
import styled from "styled-components";
import NextIcon from "../../../../assets/next_bg.png";

const Wrapper = styled.div`
  min-height: 133px;
  margin-top: 64px;
  position: relative;
  border: 3px solid ${theme.primary};
  border-radius: 10px;
  background-color: ${theme.base};
  & > div:first-child {
    position: absolute !important;
    top: -21px;
    left: 19px;
    z-index: 11;
  }
`;
const Circle = styled.div`
  position: absolute;
  top: -40px;
  z-index: 7;
  color: ${theme.primary};
  transform: translate(-50%, 0%);
  background: white;
  border-bottom: none;
  display: flex;
  justify-content: center;
  width: 169px;
  border: 3px solid #eb6c36;
  left: 50%;
  border-radius: 50%;
  height: 123px;
  & p {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
  }
  & span {
    font-size: 30px;
    line-height: 44px;
  }
`;
const Container = styled.div`
  height: 133px;
  background: ${theme.base};
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 20px 16% 17px;
  z-index: 10;
  border-radius: 10px;
  & a {
    min-width: 174px;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    color: ${theme.textSecondary};
    position: relative;
  }
  & a:hover {
    color: ${theme.link};
  }
  & a:after {
    content: url(${NextIcon});
    position: absolute;
    right: 0px;
  }
  @media (max-width: 1130px) {
    padding-left: 6%;
    padding-right: 6%;
    & a {
      font-size: 14px;
    }
  }
`;

interface LinkProps {
  href: string;
  title: string;
}

const Link = ({ href, title }: LinkProps) => (
  <NextLink href={href}>
    <a>{title}</a>
  </NextLink>
);
const StoresContainer = () => {
  const storesLink = [
    {
      href: "/",
      title: "春日井店",
    },
    {
      href: "/",
      title: "自由が丘店",
    },
    {
      href: "/",
      title: "赤坂けやき通り店",
    },
    {
      href: "/",
      title: "三光",
    },
    {
      href: "/",
      title: "長久手店",
    },
    {
      href: "/",
      title: "アリオ上尾店",
    },
  ] as LinkProps[];
  return (
    <Wrapper>
      <Image
        src="/topCarousel/check.svg"
        unoptimized={true}
        width={88}
        height={42}
      />
      <Container>
        {storesLink &&
          storesLink.map((link: LinkProps, index: number) => (
            <Link href={link.href} title={link.title} key={index} />
          ))}
      </Container>
      <Circle>
        <p>
          全国<span>122</span>店舗
        </p>
      </Circle>
    </Wrapper>
  );
};
export { StoresContainer };
