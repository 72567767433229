import { theme } from "@project/shared";
import Image from "next/image";
import React from "react";
import styled from "styled-components";

interface Props {
  image: string;
  title: string;
  description: string;
  name: string;
  index?: string;
  noIndex?: boolean;
  voice_title?: string;
}
const Card = styled.div`
  background: ${theme.base};
  width: 30%;
  margin: 20px 1%;
  max-width: 380px;
  min-height: 350px;
  box-shadow: -1.22465e-15px 20px 20px rgba(4, 0, 0, 0.0784314);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  & > div:first-child {
    max-height: 250px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    img {
      height: 200px !important;
    }
  }
  @media (max-width: 980px) {
    width: 48%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;
const Title = styled.p`
  margin-bottom: 16px;
  color: ${theme.dark1};
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;
const Description = styled.p`
  margin-bottom: 0px;
  color: ${theme.primaryText};
  font-size: 14px;
  line-height: 24px;
  white-space: pre-line;
`;
const Top = styled.div`
  position: absolute;
  top: -60px;
  z-index: 1;
`;
const Paw = styled.div`
  background-image: url("/paw.svg");
  height: 51px;
  width: 53px;
  display: flex;
  font-weight: bold;
  font-size: 17px;
  padding-top: 19px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  color: ${theme.base};
`;
const Content = styled.div`
  padding: 0px 22px 30px 18px;
  position: relative;
  min-height: 188px;
  margin-bottom: 40px;
  height: 100%;
  width: 100%;
`;
const Name = styled.p`
  max-width: 220px;
  min-width: 100px;

  padding: 10px;
  display: flex;
  border-radius: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  justify-content: center;
  align-items: center;
  margin: -17px auto 24px;
  background-color: ${theme.lightPrimary};
`;
const VoiceCard = ({
  image,
  title,
  description,
  index,
  name,
  voice_title,
  noIndex,
}: Props) => {
  return (
    <Card>
      <Image
        src={image}
        height={220}
        width={268}
        objectFit="cover"
        objectPosition="center"
        unoptimized={true}
      />
      <Content>
        <Name>{name ? `${title}/${name}` : title}</Name>
        <Title>{voice_title} </Title>
        <Description>{description}</Description>
      </Content>
      {!noIndex && (
        <Top>
          <Paw>{index}</Paw>
        </Top>
      )}
    </Card>
  );
};

export { VoiceCard };
