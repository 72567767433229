import { theme } from "@project/shared";
import React from "react";
import styled from "styled-components";
import { Typography } from "antd";
const { Paragraph } = Typography;
interface Props {
  image: string;
  date: string;
  content: string;
}
const Card = styled.div`
  background: ${theme.base};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  & > div:first-child {
    width: 100%;
    height: 170px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
`;
const Content = styled.div`
  margin-bottom: 0px;
  color: ${theme.primaryText};
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
`;
const ContentWrapper = styled.div`
  padding: 17px 20px;
`;
const Date = styled.p`
  margin-bottom: 7px;
  color: ${theme.lightText};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 170px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 0 0;
`;

const ColumnCard = ({ image, date, content }: Props) => {
  return (
    <Card>
      <StyledImage src={image} />
      <ContentWrapper>
        <Date>{date}</Date>
        <Content>
          {" "}
          <Paragraph ellipsis={{ rows: 3, expandable: false, symbol: "more" }}>
            {content}
          </Paragraph>
        </Content>
      </ContentWrapper>
    </Card>
  );
};

export { ColumnCard };
