import { theme, Typography } from "@project/shared";
import React, { useState } from "react";
import { PetCollapsePart, PetPack } from "../../molecules";
import {
  dogPetPack,
  catPetPack,
  mixPetPack,
} from "@project/shared/src/utility/anshinpack";

interface PetCollapseProps {
  category?: string;
  species?: string;
}

export const PetCollapse: React.FC<PetCollapseProps> = ({
  category,
  species,
}) => {
  const [selectedTab, setSelectedTab] = useState("");
  return (
    <>
      <Typography
        bold
        breakspace
        fontSize="22px"
        lineHeight="33px"
        bottommargin="40px"
        padding="0px 0px 15px 0px"
        borderbtn={`1px solid ${theme.primary}`}
        color={theme.primary}
        textAlign="center"
      >
        {category === "dog" &&
          species === "126" &&
          "あんしんパック For Mix Dog"}
        {category === "dog" && species != "126" && "あんしんパック For Dog"}
        {category === "cat" && "あんしんパック For Cat"}
      </Typography>

      {/* case I if category is "dog" && species is "126" i.e MIX */}
      {category === "dog" && species === "126" && (
        <div>
          <PetCollapsePart
            current={selectedTab == "あんしんパックA"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックA" ? "" : "あんしんパックA"
              )
            }
            title="あんしんパックA"
            price={mixPetPack.planA.price}
          />
          {selectedTab == "あんしんパックA" && (
            <PetPack
              days={mixPetPack.planA.days}
              petPackList={mixPetPack.planA.rows}
              support={mixPetPack.planA.support}
            />
          )}
          <PetCollapsePart
            current={selectedTab == "あんしんパックB"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックB" ? "" : "あんしんパックB"
              )
            }
            title="あんしんパックB"
            price={mixPetPack.planB.price}
          />
          {selectedTab == "あんしんパックB" && (
            <PetPack
              days={mixPetPack.planB.days}
              petPackList={mixPetPack.planB.rows}
              support={mixPetPack.planB.support}
            />
          )}
          <PetCollapsePart
            current={selectedTab == "あんしんパックC"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックC" ? "" : "あんしんパックC"
              )
            }
            title="あんしんパックC"
            price={mixPetPack.planC.price}
          />
          {selectedTab == "あんしんパックC" && (
            <PetPack
              days={mixPetPack.planC.days}
              petPackList={mixPetPack.planC.rows}
              support={mixPetPack.planC.support}
            />
          )}
        </div>
      )}

      {/* case II if category is "cat" cat wala show*/}
      {category === "cat" && (
        <div>
          <PetCollapsePart
            current={selectedTab == "あんしんパックA"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックA" ? "" : "あんしんパックA"
              )
            }
            title="あんしんパックA"
            price={catPetPack.planA.price}
          />
          {selectedTab == "あんしんパックA" && (
            <PetPack
              days={catPetPack.planA.days}
              petPackList={catPetPack.planA.rows}
              support={catPetPack.planA.support}
            />
          )}
          <PetCollapsePart
            current={selectedTab == "あんしんパックB"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックB" ? "" : "あんしんパックB"
              )
            }
            title="あんしんパックB"
            price={catPetPack.planB.price}
          />
          {selectedTab == "あんしんパックB" && (
            <PetPack
              days={catPetPack.planB.days}
              petPackList={catPetPack.planB.rows}
              support={catPetPack.planB.support}
            />
          )}
          <PetCollapsePart
            current={selectedTab == "あんしんパックC"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックC" ? "" : "あんしんパックC"
              )
            }
            title="あんしんパックC"
            price={catPetPack.planC.price}
          />
          {selectedTab == "あんしんパックC" && (
            <PetPack
              days={catPetPack.planC.days}
              petPackList={catPetPack.planC.rows}
              support={catPetPack.planC.support}
            />
          )}
        </div>
      )}

      {/* case III if category is "dog" only  show dog wala  */}
      {category === "dog" && species != "126" && (
        <div>
          <PetCollapsePart
            current={selectedTab == "あんしんパックA"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックA" ? "" : "あんしんパックA"
              )
            }
            title="あんしんパックA"
            price={dogPetPack.planA.price}
          />
          {selectedTab == "あんしんパックA" && (
            <PetPack
              days={dogPetPack.planA.days}
              petPackList={dogPetPack.planA.rows}
              support={dogPetPack.planA.support}
            />
          )}
          <PetCollapsePart
            current={selectedTab == "あんしんパックB"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックB" ? "" : "あんしんパックB"
              )
            }
            title="あんしんパックB"
            price={dogPetPack.planB.price}
          />
          {selectedTab == "あんしんパックB" && (
            <PetPack
              days={dogPetPack.planB.days}
              petPackList={dogPetPack.planB.rows}
              support={dogPetPack.planB.support}
            />
          )}
          <PetCollapsePart
            current={selectedTab == "あんしんパックC"}
            selectTab={() =>
              setSelectedTab((current) =>
                current == "あんしんパックC" ? "" : "あんしんパックC"
              )
            }
            title="あんしんパックC"
            price={dogPetPack.planC.price}
          />
          {selectedTab == "あんしんパックC" && (
            <PetPack
              days={dogPetPack.planC.days}
              petPackList={dogPetPack.planC.rows}
              support={dogPetPack.planC.support}
            />
          )}
        </div>
      )}
    </>
  );
};
