import { theme, Typography } from "@project/shared";
import React from "react";
import styled from "styled-components";
import NumberFormat from "react-number-format";

export interface IRow {
  current?: boolean;
}

export interface IPetCollapsePart {
  title?: string;
  price?: string;
  current?: boolean;
  selectTab?: any;
}

const TypographyFirstStyled = styled(Typography)`
  @media (max-width: 450px) {
    font-size: 14px;
  }
`;
const TypographySecondStyled = styled(Typography)`
  @media (max-width: 450px) {
    font-size: 16px;
  }
`;
const TypographyThirdStyled = styled(Typography)`
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

const RowStyled = styled.div<IRow>`
  height: 60px;
  position: relative;
  background-color: ${({ current }) =>
    current ? theme.primaryLight : theme.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-bottom: 13px;
  cursor: pointer;
  @media (max-width: 450px) {
    justify-content: flex-start;
    padding-left: 12px;
  }
  & .row-content {
    width: 400px;
    @media (max-width: 450px) {
      width: unset;
    }
  }
  & > :last-child {
    position: absolute;
    right: 19px;
    cursor: pointer;
    @media (max-width: 360px) {
      height: 21px;
      width: 21px;
      right: 8px;
    }
  }
`;

export const PetCollapsePart: React.FC<IPetCollapsePart> = ({
  title,
  price,
  current,
  selectTab,
}) => {
  return (
    <RowStyled
      current={current}
      onClick={() => {
        selectTab(title);
      }}
    >
      <div className="row-content">
        <TypographyFirstStyled
          fontSize="18px"
          letterSpacing="0.9px"
          padding="0px 17px 0px 0px"
          lineHeight="23px"
          bold
          display="inline-block"
        >
          {title}
        </TypographyFirstStyled>
        <TypographySecondStyled
          fontSize="20px"
          lineHeight="23px"
          bold
          display="inline-block"
        >
          <NumberFormat
            thousandSeparator={true}
            displayType={"text"}
            value={price}
            prefix={"¥"}
            suffix={"-"}
          />
        </TypographySecondStyled>
        <TypographyThirdStyled fontSize="14px" display="inline-block">
          （税込{" "}
          <NumberFormat
            thousandSeparator={true}
            displayType={"text"}
            value={Math.floor(Number(price) * 1.1)}
            prefix={"¥"}
          />
          ）
        </TypographyThirdStyled>
      </div>

      <img src={current ? "/up.svg" : "/down.svg"} />
    </RowStyled>
  );
};
