import React from "react";

export interface SearchQueryProps {
  query: any;
  UpdateQuery: (value: any, key: string) => void;
}
export const DEFAULT_VALUE = {
  query: {
    group: "",
    species: "",
    fur_color: "",
    gender: "",
    price: "",
    prefecture: "",
    shop_name: "",
    vaccination: "",
    keyword: "",
    category: "",
    sortby: "1",
    commitment_conditions: [],
  },
  UpdateQuery: () => {
    // something goes
  },
};

export const SearchQueryContext = React.createContext<SearchQueryProps>(
  DEFAULT_VALUE
);
