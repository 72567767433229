import { Button, theme, SearchPet } from "@project/shared";
import React, { useState, useContext } from "react";
import { Drawer } from "antd";
import styled from "styled-components";
import MenuIcon from "../../../assets/open.svg";
import { CloseOutlined } from "@ant-design/icons";
import BottomNavigationMenu from "../../molecules/BottomNavigationMenu";
import { PetContext } from "../../molecules";
import { useRouter } from "next/router";

const Wrapper = styled.div`
  display: flex;
  position: sticky;
  bottom: 0px;
  background: ${theme.base};
  align-items: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.01);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.09);
  padding: 40px 46px 40px 40px;
  justify-content: space-between;
  z-index: 1050;
  & .super {
    color: ${theme.yellow1};
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
  }
  & button {
    font-size: 22px;
    line-height: 33px;
    font-weight: bold;
    width: 100%;
    margin-right: 15px;
    border-radius: 10px;
    box-shadow: -3.06162e-16px 5px 5px rgba(4, 0, 0, 0.0509804) !important;
  }
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 700px) {
    & button {
      min-height: 70px;
      font-size: 20px;
    }
  }
  @media (max-width: 595px) {
    padding: 25px;
    & button {
      min-height: 50px;
      font-size: 17px;
    }
  }
  @media (max-width: 515px) {
    padding: 20px;
    & button {
      font-size: 15px;
      margin-right: 10px;
    }
  }
  @media (max-width: 475px) {
    padding: 14px;
    & button {
      font-size: 14px;
      min-height: 40px;
      margin-right: 7px;
    }
  }
  @media (max-width: 405px) {
    padding: 15px 8px;
    align-items: center;
    & button {
      padding: 0px;
      font-size: 12px;
      min-height: 35px;
      margin-right: 5px;
    }
  }
`;

const StyleDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    transition: transform 0.5s cubic-bezier(0.7, 0.3, 0.1, 1),
      box-shadow 0.5s cubic-bezier(0.7, 0.3, 0.1, 1),
      -webkit-transform 0.5s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
  line-height: 33px;
  font-weight: bold;
  align-items: center;
  margin-left: 9px;
  color: ${theme.primary};

  @media (min-width: 596px) {
    & span {
      margin-top: 12px;
    }
  }
  @media (max-width: 595px) {
    font-size: 17px;
    line-height: unset;
    & svg {
      height: 16px;
      width: auto;
    }
  }
  @media (max-width: 515px) {
    margin-left: 0px;
  }
  @media (max-width: 475px) {
    font-size: 14px;
  }
  @media (max-width: 405px) {
    font-size: 12px;

    margin-left: 5px;
    & span {
      margin-top: -8px;
    }
    & .close-text {
      margin-top: 1px;
      font-size: 12px;
    }
  }
`;

const Favorate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 375px) {
    & span.super {
      font-size: 20px;
    }
  }
`;

const BottomLinks = () => {
  const [visible, setVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const { favItem } = useContext(PetContext);
  const router = useRouter();

  const showDrawer = () => {
    setVisible(true);
    setIsDrawerVisible(false);
  };

  const closeDrawer = () => {
    setVisible(false);
    setIsDrawerVisible(false);
  };
  const goTofavHandler = () => {
    setIsDrawerVisible(false);
    router.push("/favourite");
  };

  const handleClose = () => {
    setIsDrawerVisible(false);
  };
  const ShowModal = () => {
    if (isDrawerVisible) {
      setIsDrawerVisible(false);
    } else {
      setIsDrawerVisible(true);
    }
  };

  const handleRouteSearchStore = () => {
    setIsDrawerVisible(false);
    router.push("/search-store");
  };

  return (
    <Wrapper>
      <StyleDrawer
        placement={"top"}
        closable={true}
        onClose={handleClose}
        visible={isDrawerVisible}
        height={"90vh"}
        bodyStyle={{ padding: 10 }}
      >
        <SearchPet CancelModal={handleClose} />
      </StyleDrawer>
      <Button type="primary" minheight={90} onClick={() => ShowModal()}>
        ペット検索
      </Button>
      <Button
        type="primary"
        minheight={90}
        onClick={() => handleRouteSearchStore()}
      >
        ショップ検索
      </Button>
      <Button type="primary" minheight={90} onClick={goTofavHandler}>
        <Favorate>
          お気に入り
          <span className="super">{favItem}</span>
        </Favorate>
      </Button>
      {visible ? (
        <Menu onClick={closeDrawer}>
          <CloseOutlined />
          <span className="close-text">MENU</span>
        </Menu>
      ) : (
        <Menu onClick={showDrawer} className="menu">
          <MenuIcon />
          <span className="close-text">MENU</span>
        </Menu>
      )}

      <BottomNavigationMenu visible={visible} setVisible={setVisible} />
    </Wrapper>
  );
};

export { BottomLinks };
