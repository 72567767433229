import styled from "styled-components";
import { theme } from "@project/shared/src/theme";
import { Button } from "@project/shared/src";
import Link from "next/link";

const Wrapper = styled.div`
  display: flex;
  height: 89px;
  background: #fff;
  border-top: 1px dotted ${theme.primary};
  border-bottom: 1px dotted ${theme.primary};
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 81px;

  @media (max-width: 1480px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    & > div:first-child {
      display: none;
    }
    height: 146px;
    gap: 15px;
    margin: 22px 45px;
    flex-direction: column;
    justify-content: center;
    border-top: 1px dotted ${theme.primary};
    border-bottom: 1px dotted ${theme.primary};
    margin-top: 44px;
  }
  @media (max-width: 520px) {
    flex-wrap: wrap;
    justify-content: center;
    height: 140px;
    align-items: center;
    padding: 30px 0;
    gap: 10px;
    margin-top: 32px;
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const StyledTitle = styled.p`
  color: ${theme.primary};
  font-weight: 600;
  font-size: 28px;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  display: block;
  & Button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 520px) {
    & button {
      width: 120px;
      height: 30px;
      & span {
        margin-top: -5px;
        font-size: 12px;
      }
    }
  }
`;

interface Props {
  category?: string;
  title?: string;
}

export const PageTop: React.FC<Props> = ({ category, title }) => {
  return (
    <Wrapper>
      <div />
      <StyledTitle>{title}</StyledTitle>
      <ButtonWrapper>
        <Link href={`/pet-dictionary?category=${category}`}>
          <Button
            htmlType="button"
            color="#fff"
            borderradius={17}
            background="#1589CF"
            minheight={34}
            minwidth={140}
            bold
            fontsize={14}
          >
            {category === "dog" ? "Dog Picture Book" : "Cat Picture Book"}
          </Button>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  );
};
