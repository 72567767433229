interface EmailProps {
  body: string;
  subject: string;
  children: React.ReactNode;
}

const EmailShare = ({ children, subject, body }: EmailProps) => {
  return (
    <>
      <a
        target="_blank"
        href={`mailto:?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(body)}`}
      >
        {children}
      </a>
    </>
  );
};

export { EmailShare };
