import React from "react";

export interface QueryProps {
  query: any;
  AddQuery: (value: any, key: string) => void;
}
export const DEFAULT_VALUE = {
  query: {
    prefectures: [],
    malls: [],
    services: [],
    keyword: "",
  },
  AddQuery: () => {
    // something goes
  },
};

export const QueryContext = React.createContext<QueryProps>(DEFAULT_VALUE);
