import React from "react";
import styled from "styled-components";
import { Layout, Collapse } from "antd";
import { theme } from "@project/shared";
import NextLink from "next/link";
import NextIcon from "../../../assets/next.svg";
import { useRouter } from "next/router";
import CaretRightOutlined from "../../../assets/icons/right_arrow.svg";
import PlusOutlined from "../../../assets/icons/plus.svg";
import MinusOutlined from "../../../assets/icons/minus.svg";

interface LinkType {
  href: string;
  title: string;
}

interface LinkProps {
  children?: any;
  className?: string;
  href?: string;
}

const Container = styled(Layout.Footer)`
  display: flex;
  flex-direction: column;
  padding: 90px 15px 40px;
  background: ${theme.base};
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  & p,
  a {
    color: ${theme.textPrimary};
  }
  & a:hover {
    color: ${theme.link};
  }
  & .footerLink--active {
    color: #1890ff;
  }
  @media (max-width: 769px) {
    padding-left: 0;
    padding-right: 0;
  }
`;
const ListsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TitleLink = styled.a<{ marginBottom?: number; noMargin?: boolean }>`
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  white-space: break-spaces;
  margin-bottom: ${({ marginBottom, noMargin }) =>
    marginBottom ? `${marginBottom}px` : noMargin ? "0px" : "20px"};
  & svg {
    margin-left: 12px;
  }
`;
const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 1060px) {
    flex-wrap: wrap;
    & a {
      text-align: center;
    }
    & > a:first-child {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 768px) {
    & > a:first-child {
      margin: 0px;
      min-height: 271px;
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 560px) {
    & > a:first-child {
      min-height: 150px;
    }
  }
`;

const Lists = styled.ul<{ marginBottom?: number }>`
  list-style-type: "― ";
  padding-left: 20px;
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0px"};
  color: ${theme.grey2};
  & li {
    padding-left: 3px;
  }
  & a {
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 53px;

  @media (min-width: 769px) {
    margin-left: auto;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-bottom: 38px;
    border-bottom: 1px solid ${theme.grey2};
    & a {
      width: 100%;
      text-align: center;
      font-weight: normal;
      font-size: 28px;
      line-height: 41px;
      margin-bottom: 26px;
      & svg {
        display: none;
      }
    }
  }
  @media (max-width: 560px) {
    margin-top: 20px;
    & a {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  @media (max-width: 415px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const BottomLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 78px;
  min-width: 476px;
  @media (max-width: 768px) {
    display: none;
    min-width: 100%;
    margin-top: 0px;
  }
`;
const BottomLinksSM = styled(BottomLinks)`
  display: flex !important;
  flex-wrap: wrap;
  & a {
    width: 50%;
    display: flex;
    font-size: 18px;
    line-height: 80px;
    align-items: center;
    & svg {
      margin-left: 20px;
      font-size: 12px;
    }
  }
  padding: 22px 46px 0px;
  @media (min-width: 769px) {
    display: none !important;
  }
  @media (max-width: 415px) {
    padding: 22px 20px 0px;
    & svg {
      margin-left: 12px !important;
    }
  }
`;
const MobileContainer = styled.div`
  & .ant-collapse {
    border: none;
    border-top: 1px solid ${theme.grey2};
    & > a {
      background-color: ${theme.lightGrey};
    }
  }
  & .ant-collapse-header {
    min-height: 100px;
    font-size: 22px;
    font-weight: 500;
    background-color: ${theme.lightGrey};
    padding: 0px !important;
    padding-left: 25px !important;
    display: flex;
    align-items: center;
    & svg {
      top: 42px !important;
      right: 25px !important;
    }
  }
  & .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    padding: 0px;
    & a {
      font-weight: 400;
      padding-left: 45px;
    }
    & > a:last-child {
      border-bottom: none !important;
    }
  }
  & a {
    min-height: 100px;
    padding-left: 25px;
    font-size: 22px;
    font-weight: 500;
    justify-content: space-between;
    padding-right: 25px;
    border-bottom: 1px solid ${theme.grey2};
    display: flex;
    align-items: center;
  }
  & > a {
    background-color: ${theme.lightGrey};
  }
  & a svg {
    font-size: 12px;
  }
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 769px) {
    & a,
    .ant-collapse-header {
      font-size: 16px;
      min-height: 60px;
      & svg {
        top: 25px !important;
        height: 16px;
        width: 16px;
      }
    }
  }
  @media (max-width: 415px) {
    & a,
    .ant-collapse-header {
      font-size: 14px;
      min-height: 55px;
      padding-left: 20px !important;
      padding-right: 20px;
      & svg {
        top: 21px !important;
        right: 20px !important;
      }
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  & a {
    padding: 10px;
  }
`;

const MailButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  @media (min-width: 769px) {
    display: none;
  }
`;

const Title = ({
  href,
  children,
  marginBottom,
  noMargin,
  target,
  className,
}: any) => (
  <NextLink href={href} passHref>
    <TitleLink
      noMargin={noMargin}
      marginBottom={marginBottom}
      target={target}
      className={className}
    >
      {children}
    </TitleLink>
  </NextLink>
);
const Link = ({ href, children, className }: LinkProps) => (
  <NextLink href={href}>
    <a className={className}>{children}</a>
  </NextLink>
);
const TitleLabel = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
const CopyRight = styled.p`
  text-align: center;
  margin-bottom: 0px;
  margin-top: 57px;
  font-size: 12px;
  line-height: 18px;
  @media (max-width: 768px) {
    margin-top: 76px;
    background: #f4f6fa;
    min-height: 111px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 30px;
    justify-content: center;
  }
`;
const ListItem = ({ href, children }) => {
  const router = useRouter();
  return (
    <li>
      <Link
        href={href}
        className={`${router.asPath.startsWith(href) && "footerLink--active"}`}
      >
        {children}
      </Link>
    </li>
  );
};

const Footer = () => {
  const { Panel } = Collapse;
  const router = useRouter();
  const findPet = [
    { href: "/find-a-pet?category=dog&all=true", title: "ワンちゃんを探す" },
    { href: "/find-a-pet?category=cat&all=true", title: "ネコちゃんを探す" },
    { href: "/find-a-pet?category=small_pets&all=true", title: "小動物を探す" },
    { href: "/find-a-pet?category=fish&all=true", title: "観賞魚を探す" },
  ] as LinkType[];

  return (
    <Container>
      <LinksContainer>
        <NextLink href="/">
          <a>
            <img src="/logo.svg" width={280} />
          </a>
        </NextLink>
        <ListsWrapper>
          <TitleLabel>ペットを探す</TitleLabel>
          <Lists>
            {findPet.map((item: LinkType, index: number) => (
              <ListItem key={index} href={item.href}>
                {item.title}
              </ListItem>
            ))}
          </Lists>
        </ListsWrapper>
        <ListsWrapper>
          <TitleLabel>ペットショップを探す</TitleLabel>
          <Lists marginBottom={30}>
            <ListItem href="/search-store">ペットショップ一覧</ListItem>
          </Lists>
          <TitleLabel>{"トリミングサロンを探す"}</TitleLabel>
          <Lists>
            <ListItem href="/search-trimming">トリミングサロン一覧</ListItem>
          </Lists>
        </ListsWrapper>
        <ListsWrapper>
          <Title
            href="/about-us"
            className={`${
              router.pathname.startsWith("/about-us") && "footerLink--active"
            }`}
          >
            {"コーワペッツ・\nペッツフレンズについて　"}
          </Title>
          <Title
            href="/voices"
            className={`${
              router.pathname.startsWith("/voices") && "footerLink--active"
            }`}
          >
            {"お客様の声　"}
          </Title>
          <Title
            href="/column-list"
            className={`${
              router.pathname.startsWith("/column-list") && "footerLink--active"
            }`}
          >
            {"コラム　"}
          </Title>
          <Title
            href="/pet-dictionary"
            className={`${
              router.pathname.startsWith("/pet-dictionary") &&
              "footerLink--active"
            }`}
          >
            {"ペット図鑑　"}
          </Title>
          <Title
            href="/news"
            className={`${
              router.pathname.startsWith("/news") && "footerLink--active"
            }`}
          >
            {"お知らせ"}
          </Title>
        </ListsWrapper>
        <ListsWrapper>
          <Title href="/about-us">{"求人情報"}</Title>
        </ListsWrapper>
      </LinksContainer>
      <MobileContainer>
        <Collapse
          expandIconPosition="right"
          expandIcon={({ isActive }) =>
            isActive ? <MinusOutlined /> : <PlusOutlined />
          }
        >
          <Panel header="ペット検索" key="1">
            {findPet.map((item: LinkType, index: number) => (
              <Link key={index} href={item.href}>
                {item.title}
                <CaretRightOutlined />
              </Link>
            ))}
          </Panel>
          <Link href={"/search-store"} key="2">
            ペットショップを探す
            <CaretRightOutlined />
          </Link>
          <Panel header="トリミングサロン" key="3">
            <Link href={"/search-trimming"}>
              トリミングサロン一覧
              <CaretRightOutlined />
            </Link>
          </Panel>
        </Collapse>
        <Link href={"/about-us"}>
          コーワペッツ・ペッツフレンズについて
          <CaretRightOutlined />
        </Link>
        <Link href={"/voices"}>
          お客様の声
          <CaretRightOutlined />
        </Link>
        <Link href={"/column-list"}>
          コラム
          <CaretRightOutlined />
        </Link>
        <Link href={"/pet-dictionary"}>
          ペット図鑑
          <CaretRightOutlined />
        </Link>
      </MobileContainer>
      <IconContainer>
        <Title
          href="/group-company"
          noMargin
          className={`${
            router.pathname === "/group-company" && "footerLink--active"
          }`}
        >
          グループ会社リンク <NextIcon />
        </Title>
        <LogoWrapper>
          <a href="http://www.hattori-kowa.jp/" target={"_blank"}>
            <img
              className="image1"
              src="/footer/logo1.svg"
              height={44}
              width={37}
            />
          </a>
          <a href="https://www.supercat.co.jp/" target={"_blank"}>
            <img
              className="image2"
              src="/footer/logo2.svg"
              height={40}
              width={99}
            />
          </a>
          <a href="http://animallogistics.co.jp/" target={"_blank"}>
            <img className="image3" src="/footer/logo3.svg" />
          </a>
        </LogoWrapper>
      </IconContainer>
      <BottomLinks>
        <Link
          href="/contact"
          className={`${
            router.pathname.startsWith("/contact") && "footerLink--active"
          }`}
        >
          お問い合わせ
        </Link>
        <Link
          href="/privacy"
          className={`${
            router.pathname.startsWith("/privacy") && "footerLink--active"
          }`}
        >
          個人情報保護方針
        </Link>
        <Link
          href="/company"
          className={`${
            router.pathname.startsWith("/company") && "footerLink--active"
          }`}
        >
          企業情報
        </Link>
      </BottomLinks>

      <BottomLinksSM>
        <Link
          href="/company"
          className={`${
            router.pathname.startsWith("/company") && "footerLink--active"
          }`}
        >
          企業情報 <CaretRightOutlined />
        </Link>
        <Link
          href="/news"
          className={`${
            router.pathname.startsWith("/news") && "footerLink--active"
          }`}
        >
          お知らせ
          <CaretRightOutlined />
        </Link>
        <a href="/about-us">
          求人情報
          <CaretRightOutlined />
        </a>
        <Link
          href="/contact"
          className={`${
            router.pathname.startsWith("/contact") && "footerLink--active"
          }`}
        >
          お問い合わせ
          <CaretRightOutlined />
        </Link>
      </BottomLinksSM>
      <MailButton>
        <a href="https://form.run/@kowapets " target="_blank">
          <img
            src="https://storage.googleapis.com/kowapets-production-bucket/0097c932-453f-461b-aac5-f0c138cefd60.png"
            height="40"
          />
        </a>
      </MailButton>
      <CopyRight>KOWAPETS CORPORATION Co., Ltd.</CopyRight>
    </Container>
  );
};

export { Footer };
