import { SearchQueryProps } from "./searchQueryContext";
import React, { useState } from "react";
export const useSearchQuery = (): SearchQueryProps => {
  const [query, setQuery] = useState({
    group: "",
    species: "",
    fur_color: "",
    gender: "",
    price: "",
    prefecture: "",
    shop_name: "",
    vaccination: "",
    keyword: "",
    category: "",
    sortby: "1",
    commitment_conditions: [],
  } as any);

  const UpdateQuery = React.useCallback((value: any, key: string) => {
    if (key == "add") setQuery(value);
  }, []);

  return {
    query,
    UpdateQuery,
  };
};
