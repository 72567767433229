import { Button, theme } from "@project/shared";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import { ColumnCard } from "../../../molecules";
import { useRouter } from "next/router";
import moment from "moment";

interface ColumnType {
  id: string;
  image: string;
  write_date: any;
  title: string;
}
const Container = styled.div`
  padding: 98px 0 234px;
  position: relative;
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1480px;
  margin: auto;
  & button {
    margin: 40px auto 0px;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    & button {
      width: 100%;
      max-width: 400px;
      min-width: auto;
    }
  }
  @media (max-width: 475px) {
    & button {
      font-size: 16px;
      min-height: 60px;
      margin-top: 20px;
    }
  }
`;

const Heading = styled.p`
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 23px;
  color: ${theme.primary};
  @media (max-width: 475px) {
    margin-bottom: 0px;
  }
`;
const Title = styled.p`
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  color: ${theme.primary};
  margin-bottom: 70px;
  @media (max-width: 475px) {
    margin-bottom: 60px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > a {
    width: 21.51%;
    border-radius: 10px;
    box-shadow: -1.22465e-15px 20px 20px rgba(4, 0, 0, 0.0784314);
    & > div {
      align-items: flex-start !important;
    }
  }
  @media (max-width: 1300px) {
    & > a {
      width: 48%;
      margin-bottom: 6%;
    }
  }
  @media (max-width: 575px) {
    & > a {
      width: 100%;
    }
  }
`;

const ColumnSection = ({ columnData }) => {
  const router = useRouter();

  return (
    <Container>
      <Wrapper>
        <Heading>COLUMNS</Heading>
        <Title>コラム</Title>
        <CardWrapper>
          {columnData &&
            columnData.map((column: ColumnType, index: number) => (
              <Link key={index} href={`/column-details/${column.id}`}>
                <a>
                  <ColumnCard
                    key={index}
                    image={column.image || "/default.png"}
                    date={moment(column.write_date).format("YYYY.MM.DD")}
                    content={column.title}
                  />
                </a>
              </Link>
            ))}
        </CardWrapper>
        <Button
          onClick={() => router.push("/column-list")}
          rounded
          type="primary"
          minheight={60}
          minwidth={300}
        >
          コラムをもっと見る
        </Button>
      </Wrapper>
    </Container>
  );
};
export { ColumnSection };
