import styled from "styled-components";
import { theme } from "@project/shared";

interface IProps {
  featureTitle?: string;
  featureContent?: string;
}

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #959595;
  padding-bottom: 10px;
  padding-top: 10px;
  @media (max-width: 520px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;
const StyledTitle = styled.p`
  color: ${theme.primary};
  font-size: 16px;
  width: 25%;
  padding-left: 5px;
  margin-bottom: 0.5em;
  font-weight: 500;
  @media (max-width: 520px) {
    width: auto;
    margin: 0;
    padding: 0;
  }
`;
const StyledContent = styled.p`
  color: #313131;
  width: 75%;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 0.5em;
  font-weight: 400;
  font-size: 14px;
  @media (max-width: 520px) {
    width: auto;
    padding-right: 4px;
    font-size: 14px;
    padding-left: 0px;
    line-height: 28px;
  }
`;

export const PetFeatureColumn: React.FC<IProps> = ({
  featureTitle,
  featureContent,
}) => {
  return (
    <Wrapper>
      <StyledTitle>{featureTitle}</StyledTitle>
      <StyledContent>{featureContent}</StyledContent>
    </Wrapper>
  );
};
