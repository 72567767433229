import styled from "styled-components";

const Wrapper = styled.div`
  max-width: 1480px;
  background: #fff;
  display: flex;
  margin-top: 80px;
  align-items: flex-start;
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 70px;
  }
  @media (max-width: 768px) {
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    margin-top: 59px;
  }
  @media (max-width: 520px) {
    margin: 0;
    margin-top: 30px;
  }
`;
const ImageWrapper = styled.div`
  background: #f5f7fa;
  margin-right: 30px;
  flex: 1;
  border-radius: 10px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
  @media (max-width: 520px) {
    margin-right: 0;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const OverviewWrapper = styled.div`
  background: #fff;
  flex: 1;
  @media (max-width: 520px) {
    margin-top: 15px;
  }
`;
const PetName = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const StyledNameJap = styled.p`
  font-weight: 600;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 28px;
  color: #313131;
  @media (max-width: 520px) {
    font-size: 16px;
    margin-top: 8px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const StyledName = styled.p`
  color: #aaa;
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 20px;
  margin-top: 15px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-left: 0;
  }
`;

const BtnWrapper = styled.div`
  white-space: nowrap;
  border: 1px solid #1589cf;
  border-radius: 15px;
  margin-top: 20px;
  display: inline-block;
  padding: 2px 10px;
  color: #1589cf;
`;

const Details = styled.div`
  text-align: justify;
  margin-top: 47px;
  @media (max-width: 1310px) {
    margin-top: 14px;
    font-size: 14px;
  }
  @media (max-width: 1215px) {
    font-size: 14px;
    margin-top: 43px;
  }
  @media (max-width: 520px) {
    margin-top: 25px;
    font-size: 14px;
  }
`;

interface Props {
  image?: string;
  nameJP?: string;
  nameEN?: string;
  country?: string;
  description?: string;
}

export const PetOverviewCard: React.FC<Props> = (props) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <StyledImage src={props.image} />
      </ImageWrapper>
      <OverviewWrapper>
        <PetName>
          <StyledNameJap>{props.nameJP}</StyledNameJap>
          <StyledName>{props.nameEN}</StyledName>
        </PetName>
        <BtnWrapper>原産国：{props.country}</BtnWrapper>
        <Details>{props.description}</Details>
      </OverviewWrapper>
    </Wrapper>
  );
};
