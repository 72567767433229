import styled from "styled-components";
import { PetFeatureColumn } from "../PetFeatureColumn";
import { theme } from "@project/shared";

const Wrapper = styled.div`
  height: auto;
  padding-left: 0px;

  @media (max-width: 578px) {
    padding-left: 0;
  }
`;
const StyledTitle = styled.p`
  background: ${theme.lightPrimary};
  font-size: 16px;
  color: ${theme.primary};
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
`;
const TableContainer = styled.div`
  & > div {
    p {
      display: flex;
      align-items: center;
      margin-top: 8px;
      margin-bottom: 7px;
    }
  }
  @media (max-width: 768px) {
    margin-top: 2px;
    & > div:last-child {
      border-bottom: none;
    }
  }
  @media (max-width: 520px) {
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 0;
  }
`;

interface Props {
  features?: any;
}

export const PetFeatureTable: React.FC<Props> = ({ features }) => {
  return (
    <Wrapper>
      <StyledTitle>特徴</StyledTitle>
      <TableContainer>
        {features.map((feature, index) => (
          <PetFeatureColumn
            key={`feature-${index}`}
            featureTitle={feature.label}
            featureContent={feature.value}
          />
        ))}
      </TableContainer>
    </Wrapper>
  );
};
