import Image from "next/image";
import React from "react";
import styled from "styled-components";
import { CallTag, LocationTagInfo as LocationTag } from "../../../assets";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 32px;
  border-bottom: 1px solid #959595;
  margin-bottom: 40px;
  @media screen and (max-width: 860px) {
    flex-direction: column;
    justify-content: center;
  }
`;
const ImageWrapper = styled.div`
  min-width: 263px;
  height: 175px;
  cursor: pointer;
  img {
    border-radius: 10px;
  }
  div {
    height: 175px;
  }
  @media screen and (max-width: 850px) and (min-width: 579px) {
    height: 400px;
    width: 100%;
    div {
      height: 400px;
    }
  }
  @media screen and (max-width: 578px) {
    width: 100%;
    height: 300px;
    div {
      height: 300px;
    }
  }
`;
const LocationInfo = styled.div`
  margin-left: 24px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 1rem;
  @media screen and (max-width: 850px) {
    margin-top: 35px;
    margin-left: 0px;
    max-width: 100%;
  }
`;
const Location = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  @media screen and (max-width: 820px) and (min-width: 579px) {
    svg {
      width: 30px;
      height: 45px;
    }
  }
`;
const LocationName = styled.strong`
  font-size: 16px;
  line-height: 24px;
  color: #eb6014;
  margin-left: 6px;
  border-bottom: 1px solid #eb6014;
  padding-bottom: 2px;
  @media screen and (max-width: 820px) and (min-width: 579px) {
    font-size: 30px;
    line-height: 44px;
  }
  @media screen and (max-width: 578px) {
    font-size: 18px;
    line-height: 36px;
  }
`;
const PostalCode = styled.strong`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #313131;
  @media screen and (max-width: 820px) and (min-width: 579px) {
    font-size: 28px;
    line-height: 45px;
  }
  @media screen and (max-width: 578px) {
    font-size: 18px;
    line-height: 36px;
  }
`;
const FullDetails = styled.strong`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #313131;
  @media screen and (max-width: 820px) and (min-width: 579px) {
    font-size: 28px;
    line-height: 45px;
  }
  @media screen and (max-width: 578px) {
    font-size: 18px;
    line-height: 36px;
  }
`;

const CallInfo = styled.div`
  display: flex;
  align-items: center;
  svg {
    min-width: 20px;
  }
`;
const PhoneNumber = styled.strong`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #313131;
  margin-left: 7px;
  @media screen and (max-width: 820px) and (min-width: 579px) {
    font-size: 28px;
    line-height: 45px;
  }
  @media screen and (max-width: 578px) {
    font-size: 20px;
    line-height: 36px;
  }
`;
const PetsImage = styled.div`
  @media screen and (max-width: 820px) and (min-width: 579px) {
    svg {
      width: 49px;
      height: 45px;
    }
  }
`;
const GridRow = styled.div`
  margin-left: 55px;

  @media screen and (max-width: 850px) and (min-width: 579px) {
    margin-left: 0px;
    margin-top: 43px;
    svg {
      width: 30px;
      height: 45px;
    }
  }
  @media screen and (max-width: 578px) {
    margin-top: 23px;
    margin-left: 0px;
  }
`;
const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 6px;
  & > div {
    margin-bottom: 20px;
    width: 100% !important;
    & > div {
      max-width: unset;
    }
  }
  @media (max-width: 1460px) and (min-width: 950px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: auto !important;
      margin-right: 10px;
    }
  }
  @media (max-width: 579px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
const PetsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 6px;
  & > div {
    margin-bottom: 25px;
    width: 100% !important;
    & > div {
      max-width: unset;
    }
  }
  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: auto !important;
      margin-right: 10px;
    }
  }
`;
const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexWrap = styled(FlexBox)`
  gap: 2rem;
  align-items: center;
  margin-left: 24px;
  @media screen and (max-width: 850px) {
    margin-left: 0;
  }
`;
const MailButton = styled.div``;

interface LocationCardProps {
  ShopImage?: string;
  name?: string;
  postal_code?: string;
  address?: string;
  phone?: string;
  petsImage?: Array<string>;
  serviceImage?: Array<string>;
  onclick?: () => void;
}

const LocationCard: React.FC<LocationCardProps> = (
  props: LocationCardProps
) => {
  return (
    <Wrapper>
      <ImageWrapper onClick={props.onclick}>
        <Image
          src={props.ShopImage || "/default.png"}
          layout="responsive"
          width={"100%"}
          height={"100%"}
          objectFit="cover"
          objectPosition="center"
          unoptimized={true}
        />
      </ImageWrapper>
      <div>
        <FlexBox>
          <LocationInfo onClick={props.onclick}>
            <Location>
              <LocationTag />
              <LocationName>{props?.name}</LocationName>
            </Location>
            {props?.postal_code && (
              <PostalCode>{props?.postal_code}</PostalCode>
            )}
            {props.address && <FullDetails>{props.address}</FullDetails>}
          </LocationInfo>
          <GridRow>
            <ServiceGrid>
              {props.petsImage.map((item, index) => (
                <PetsImage key={`petsImage-${index}`}>
                  <img src={`/types/${item}.png`} width={36} />
                </PetsImage>
              ))}
            </ServiceGrid>
            <PetsRow>
              {props.serviceImage.map((item, index) => (
                <PetsImage key={`serviceImage-${index}`}>
                  <img src={`/services/${item}.png`} width={60} />
                </PetsImage>
              ))}
            </PetsRow>
          </GridRow>
        </FlexBox>

        <FlexWrap>
          {props.phone && (
            <a href={`tel:${props.phone}`}>
              <CallInfo>
                <CallTag />
                <PhoneNumber>{props.phone}</PhoneNumber>
              </CallInfo>
            </a>
          )}
          <MailButton>
            <a href="https://form.run/@kowapets " target="_blank">
              <img
                src="https://storage.googleapis.com/kowapets-production-bucket/0097c932-453f-461b-aac5-f0c138cefd60.png"
                height="40"
              />
            </a>
          </MailButton>
        </FlexWrap>
      </div>
    </Wrapper>
  );
};
export { LocationCard };
