import React from "react";
import styled from "styled-components";
import { theme, Checkbox } from "@project/shared";
import { DottedTitle } from "../../molecules";

import { Row, Col, Divider } from "antd";

const Container = styled.div`
  flex-direction: column;
  background: ${theme.lightGrey};
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  width: 100%;
  padding-top: 40px;
`;

const CheckboxContainer = styled.div`
  width: 100%;
  height: auto;
  margin-top: 10px;
  & ul {
    display: flex;
    padding: 0px;
    margin: 0px;
  }
  & ul > p {
    margin-left: 5px;
    margin-top: 5px;
    padding-top: 5px;
    padding-left: 5px;
  }
`;
const ChildCheckboxContainer = styled.div`
  margin-left: 15px;
  margin-right: 15;
`;

const DisabledText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #b5b5b5;
`;
const NormalText = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #1a1a1a;
`;

const CheckboxComponent = (props) => {
  return (
    <ul>
      <Checkbox
        checked={props?.checked}
        onChange={props?.onChange}
        name={props?.name}
        disabled={props?.disabled}
      />
      {props?.disabled ? (
        <DisabledText> {props?.title}</DisabledText>
      ) : (
        <NormalText>{props?.title}</NormalText>
      )}
    </ul>
  );
};

interface ChildType {
  title?: string;
  checked?: boolean;
  divider?: boolean;
  disabled?: boolean;
  childrens?: Array<ChildChildType>;
}

interface ChildChildType {
  title?: string;
  checked?: boolean;
  disabled?: boolean;
}

interface CheckBoxFilterType {
  title?: string;
  datas?: Array<ChildType>;
  onChange?: any;
  onParentChange?: (checked: boolean, index: number) => void;
  onChildrentChange?: (
    checked: boolean,
    parentIndex: number,
    index: number
  ) => void;
}

const CheckboxFilter: React.FC<CheckBoxFilterType> = (props) => {
  const onParentChange = (checked: boolean, index: number) => {
    props?.onParentChange && props?.onParentChange(checked, index);
  };

  const onChildrentChange = (
    checked: boolean,
    parentIndex: number,
    index: number
  ) => {
    props?.onChildrentChange &&
      props?.onChildrentChange(checked, parentIndex, index);
  };

  return (
    <Container>
      <DottedTitle
        title={props.title || ""}
        borderType="solid"
        color={"#313131"}
        borderColor={"#959595"}
        fontSize={"20px"}
        lineHeight={"30px"}
        paddingTop={"10px"}
        paddingBottom={"10px"}
      />
      {props?.datas?.map((data, i) => {
        return (
          <CheckboxContainer key={i}>
            <CheckboxComponent
              title={data?.title}
              name={data?.title}
              onChange={(e: any) => onParentChange(e.target.checked, i)}
              checked={data?.checked}
              disabled={data?.disabled}
            />
            <ChildCheckboxContainer>
              <Row>
                {data?.childrens?.map((children, j) => {
                  return (
                    <Col span={12} key={j}>
                      <CheckboxComponent
                        title={children?.title}
                        name={children?.title}
                        onChange={(e: any) =>
                          onChildrentChange(e.target.checked, i, j)
                        }
                        checked={children?.checked}
                        disabled={children?.disabled}
                      />
                    </Col>
                  );
                })}
              </Row>
            </ChildCheckboxContainer>
            {data?.divider ? <Divider plain></Divider> : <></>}
          </CheckboxContainer>
        );
      })}
    </Container>
  );
};

export { CheckboxFilter };
