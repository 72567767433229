import React from "react";
import styled from "styled-components";
import { theme } from "@project/shared/src/theme";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselWrapperStyled = styled(Carousel)`
  border-radius: 10px;
  & .carousel .thumbs-wrapper {
    overflow: unset;
    margin: 0px;
  }
  & li {
    background: ${theme.base} !important;
  }

  & .slide img {
    height: 412px;
    border-radius: 10px;
    object-fit: cover;
    @media screen and (max-width: 550px) {
      height: 350px;
    }
    @media screen and (max-width: 400px) {
      height: 250px;
    }
  }

  & .thumb {
    margin-right: 0px !important;
  }
  & .thumb:hover {
    border: none;
    opacity: 0.8;
  }
  & .thumbs {
    padding-left: 0px !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;
  }
  & .thumbs > li {
    height: 97px !important;
    cursor: pointer;
    padding: 0px;
    border: none;
    width: unset !important;
    transition: none;
    border-radius: 10px;
    @media screen and (max-width: ${theme.size}) {
      height: 105px !important;
    }
    @media screen and (max-width: 550px) {
      height: 80px !important;
    }
    @media screen and (max-width: 400px) {
      height: 50px !important;
    }
  }

  & .thumbs > li > img {
    height: 100%;
    object-fit: cover;
  }

  & .thumbs .selected {
    border: none;
    padding: 0;
  }
`;

export interface IPetCarousel {
  title?: string;
  petDetails?: any;
}

const Text = styled.p`
  color: ${theme.primary};
  ${theme.typography.H1};
  margin-bottom: 34px;
  @media screen and (max-width: ${theme.size}) {
    text-align: center;
    border-top: 1px dotted ${theme.primary};
    border-bottom: 1px dotted ${theme.primary};
    padding: 20px 0px;
  }
`;

const PetCarousel: React.FC<IPetCarousel> = ({ title, petDetails }) => {
  const imgList = [
    petDetails.main_image,
    petDetails.other_image1,
    petDetails.other_image2,
    petDetails.other_image3,
  ];
  const images = imgList.filter((img) => img !== "");
  if (images.length === 0) {
    images.push("/default.png");
  }
  return (
    <>
      <Text>{title}</Text>

      <CarouselWrapperStyled
        dynamicHeight
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        swipeable
        emulateTouch
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            style={{ objectFit: "contain", backgroundRepeat: "no-repeat" }}
          />
        ))}
      </CarouselWrapperStyled>
    </>
  );
};

export { PetCarousel };
