import { PetSizeCard } from "@project/shared";
import styled from "styled-components";
import { ListTitle } from "../../../molecules";

interface PetListProps {
  title: string;
  petSizeCardList?: any;
}

const PetsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  @media (max-width: 520px) {
    justify-content: center;
    margin-top: 18px;
  }
`;

export const PetList: React.FC<PetListProps> = ({ title, petSizeCardList }) => {
  return (
    <>
      {title && <ListTitle title={title} />}
      <PetsWrapper>
        {petSizeCardList?.map((pet: any, petIndex) => (
          <PetSizeCard
            key={`pet-${petIndex}`}
            petName={pet.name_ja}
            imgSrc={pet.image}
            id={pet.id}
          />
        ))}
      </PetsWrapper>
    </>
  );
};
