import React from "react";

export interface ContextProps {
  favItem: number;
  setFavItemListCount: (count: number) => void;
  isLoaded?: boolean;
}

export const DEFAULT_VALUE = {
  favItem: 0,
  isLoaded: false,
  setFavItemListCount: () => {
    //
  },
};

export const PetContext = React.createContext<ContextProps>(DEFAULT_VALUE);
