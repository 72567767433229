import { TagV, theme, species } from "@project/shared";
import styled from "styled-components";
import Link from "next/link";

const WrapperStyled = styled.div`
  background-color: ${theme.lightGrey};
  display: flex;
  padding: 45px 120px 45px 98px;
  align-items: center;
  @media screen and (max-width: ${theme.size}) {
    display: block;
    padding: 45px 56px 45px 45px;
  }
  & > div:nth-child(2) {
    margin-left: 90px;
    @media screen and (max-width: ${theme.size}) {
      margin-left: 0px;
    }
  }
  & .popular {
    position: relative;
    top: 15px;
    @media screen and (max-width: ${theme.size}) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const PetPopular = () => {
  return (
    <WrapperStyled>
      <img src="/popular.svg" className="popular" />
      <div>
        {species.dog.slice(0, 10).map((popular, index) => (
          <Link href={`/find-a-pet?species=${popular.value}`} key={index}>
            <TagV
              key={index}
              margin="0px 11px 11px 0px"
              text={popular.name}
              padding="5px 17px"
              textColor={theme.black}
              bordercolor={theme.grey2}
            />
          </Link>
        ))}
      </div>
    </WrapperStyled>
  );
};
