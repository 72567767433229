import React from "react";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import { SearchPet, theme } from "@project/shared";
import NextIcon from "../../../../assets/next.png";
import PreviousIcon from "../../../../assets/prev.png";

export interface CarouselImage {
  id: string;
  alt: string;
  image: string;
  url?: string;
}

interface Props {
  images: CarouselImage[];
}
const Carousel = styled(ReactCarousel)`
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 19px;

  & .control-dots {
    margin: 0px;
    & li {
      height: 12px !important;
      width: 12px !important;
      box-shadow: none !important;
      background: ${theme.grey3} !important;
    }
    & .selected {
      background: ${theme.primary} !important;
    }
  }
  > div {
    position: unset !important;
  }
  & .slide {
    background: ${theme.base};
  }
  & li,
  img {
    height: 570px;
  }
  & a {
    display: flex;
  }
  & li > div > div {
    width: 100%;
    height: 570px;
  }
  & img {
    object-fit: cover;
    object-position: center;
  }
  & .control-arrow {
    top: auto !important;
    bottom: 35px !important;
    height: 40px;
    font-size: unset !important;
    width: 40px;
    opacity: 1 !important;
    background: ${theme.primary} !important;
    border: none !important;
  }
  & .control-next::before,
  .control-prev::before {
    border: 0px !important;
  }
  .control-next::before {
    content: url(${NextIcon}) !important;
  }
  & .control-prev {
    left: auto !important;
    right: 41px;
  }
  & .control-prev::before {
    content: url(${PreviousIcon}) !important;
  }
  @media (max-width: 500px) {
    & li,
    img {
      height: 380px;
      height: 45vh;
    }
    & li > div > div {
      height: 380px;
      height: 45vh;
    }
  }
`;
const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  pointer-events: none;
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 769px) {
    position: relative;
    margin-top: -632px;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 0px;
  }
`;
const Overlay = styled.div`
  display: flex;
  max-width: 1480px;
  margin: auto;
  width: 100%;
  @media (max-width: 1010px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const DefaultImage = styled.div`
  width: 100%;
  height: 570px;
  background: url("/defaultTop/top_lg.jpg") center/cover no-repeat;
  @media (max-width: 768px) {
    background-image: url("/defaultTop/top_sm.jpg");
  }
  @media (max-width: 480px) {
    height: 380px;
    height: 45vh;
  }
`;
const CarouselSection = ({ images }: Props) => {
  return (
    <Wrapper>
      {images && images?.length === 0 ? (
        <DefaultImage />
      ) : (
        <Carousel
          showStatus={false}
          showThumbs={false}
          showArrows
          swipeable
          interval={5000}
          autoPlay
          emulateTouch
          infiniteLoop
        >
          {images?.map((image) =>
            image.url ? (
              <a href={image.url} target="_blank" key={image.id}>
                <img src={image.image || "/default.png"} alt={image.alt} />
              </a>
            ) : (
              <img
                key={image.id}
                src={image.image || "/default.png"}
                alt={image.alt}
              />
            )
          )}
        </Carousel>
      )}
      <Container>
        <Overlay>
          <SearchPet />
        </Overlay>
      </Container>
    </Wrapper>
  );
};

export { CarouselSection };
