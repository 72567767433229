import { ContextProps } from "./petContext";
import React, { useState } from "react";

export const UseFavCount = (): ContextProps => {
  const [favItem, setFavCount] = useState(0);
  const setFavItemListCount = React.useCallback((count: number) => {
    setFavCount(count);
  }, []);
  return {
    favItem,
    setFavItemListCount,
  };
};
