import React from "react";
import Image from "next/image";
import { theme, Typography } from "@project/shared";
import styled from "styled-components";
import Telephone from "../../../../assets/tele.svg";
import Location from "../../../../assets/location.svg";
import { useTranslation } from "react-i18next";
import NextLink from "next/link";

const MainWrapperStyled = styled.div`
  box-shadow: -6.12323e-16px 10px 15px rgba(4, 0, 0, 0.101961);
  border-radius: 10px;
  padding: 30px 40px 40px 36px;
  @media screen and (max-width: 400px) {
    padding: 30px 25px 40px 20px;
  }
`;

const WrapperStyled = styled.div`
  display: flex;
  @media screen and (max-width: ${theme.size}) {
    display: block;
  }
  & > div:nth-child(2) > p {
    @media screen and (max-width: 1385px) and (min-width: ${theme.sizePlusOne}) {
      font-size: 16px;
    }
    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
`;

const HeaderStyled = styled.div`
  display: flex;
  border-bottom: 1px solid ${theme.primary};
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 15px;
  & > p {
    @media screen and (max-width: 400px) {
      font-size: 18px;
    }
  }
  @media screen and (max-width: ${theme.size}) {
    justify-content: center;
  }
`;

const TypographyStyled = styled(Typography)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    min-width: 20px;
  }
`;

const ImageStyled = styled.div`
  height: 165px;
  margin-right: 25px;
  max-width: 250px;
  @media screen and (max-width: 1145px) {
    max-width: 200px;
  }
  @media screen and (max-width: 1075px) {
    max-width: 165px;
  }

  @media screen and (max-width: ${theme.size}) {
    justify-content: center;
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
    height: 100%;
    width: 100%;
  }
  & > div:first-child {
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
`;

const Link = ({ href, children }) => (
  <NextLink href={href}>
    <a>{children}</a>
  </NextLink>
);

export const PetLocation = ({ shopDetails }) => {
  const { t } = useTranslation();

  let phone = shopDetails?.phone || "";
  if (phone) {
    const phoneMatch = shopDetails?.phone.match(
      /([0-9]{2,4}-?[0-9]+-?[0-9]+)/g
    );
    phoneMatch.forEach((match) => {
      phone = phone.replace(match, `<a href="tel:${match}">${match}</a>`);
    });
  }

  return (
    <MainWrapperStyled>
      <HeaderStyled>
        <Location />
        <Typography
          bold
          breakspace
          fontSize="22px"
          lineHeight="33px"
          padding="0px 0px 0px 10px"
          color={theme.primary}
        >
          この仔がいるショップ
        </Typography>
      </HeaderStyled>

      <WrapperStyled>
        {shopDetails?.main_image !== "" && (
          <ImageStyled>
            <Image
              height={300}
              width={300}
              objectFit="cover"
              unoptimized={true}
              src={shopDetails.main_image || "/default.png"}
            />
          </ImageStyled>
        )}
        <div>
          <Link href={`/shop-details/${shopDetails.id}`}>
            <Typography
              borderbtn={`1px solid ${theme.primary}`}
              fontSize="20px"
              display="inline-block"
              lineHeight="30px"
              bottommargin="13px"
              bold
              color={theme.primary}
            >
              {shopDetails?.name}【{t(shopDetails?.prefecture)}】
            </Typography>
          </Link>
          {shopDetails.address && (
            <Typography
              breakspace
              fontSize="14px"
              lineHeight="24px"
              bottommargin="13px"
              color={theme.primaryText}
            >
              {shopDetails.address}
            </Typography>
          )}
          {phone && (
            <TypographyStyled
              bold
              breakspace
              fontSize="22px"
              fontfamily="Roboto"
              lineHeight="26px"
              bottommargin="13px"
              color={theme.primaryText}
            >
              <Telephone />
              <span
                dangerouslySetInnerHTML={{
                  __html: phone,
                }}
              />
            </TypographyStyled>
          )}
          <Typography
            breakspace
            fontSize="14px"
            lineHeight="24px"
            color={theme.primaryText}
          >
            {shopDetails?.business_hours
              ? `営業時間：${shopDetails?.business_hours}`
              : ""}
            <br />
            {shopDetails?.holiday_info
              ? `休日情報：${shopDetails?.holiday_info}`
              : ""}
          </Typography>
        </div>
      </WrapperStyled>
    </MainWrapperStyled>
  );
};
