import { Button, PetLink, theme } from "@project/shared";
import { PetCard } from "../../../molecules";
import React from "react";
import styled from "styled-components";
import Image from "next/image";
import { useRouter } from "next/router";
import moment from "moment";

interface NewPets {
  birthday?: string;
  birthplace?: string;
  fur_color?: string;
  gender?: string;
  shop?: string;
  is_new?: string;
  id?: number;
  main_image?: string;
  name?: string;
  price?: string;
}
interface IProps {
  newPets?: NewPets[];
}
const Container = styled.div`
  padding: 98px 0 83px;
  background-color: ${theme.lightGrey};
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media (max-width: 768px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1480px;
  margin: auto;
`;
const Heading = styled.p`
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  position: relative;
  margin-bottom: 23px;
  & span {
    color: ${theme.primary};
    padding: 0px 14px;
  }
  @media (max-width: 435px) {
    margin-bottom: 0px;
    font-size: 26px;
  }
`;
const Title = styled.p`
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  color: ${theme.primary};
  margin-bottom: 75px;
  @media (max-width: 675px) {
    margin-bottom: 50px;
  }
  @media (max-width: 435px) {
    margin-bottom: 30px;
    font-size: 26px;
  }
  @media (max-width: 375px) {
    margin-bottom: 20px;
    font-size: 22px;
  }
`;
const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 60px;
  & > div {
    margin-bottom: 25px;
    width: 100% !important;
    & > div {
      max-width: unset;
    }
  }
  @media (max-width: 1375px) {
    grid-gap: 40px;
  }
  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 975px) {
    grid-gap: 30px;
  }
  @media (max-width: 945px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-gap: 38px;
    & > div .image > div {
      height: 170px;
    }
    & > div > div > div:last-child {
      margin-top: 21px;
    }
  }
  @media (max-width: 675px) {
    grid-gap: 15px;
  }
  @media (max-width: 374px) {
    grid-template-columns: 1fr;
  }
`;

const PetLinks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin-top: 60px;
  @media (max-width: 1185px) {
    & a {
      font-size: 14px;
    }
  }
  @media (max-width: 1050px) {
    & a {
      font-size: 18px;
    }
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    & > div {
      max-width: 500px;
      margin: auto;
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    & a {
      font-size: 18px;
    }
  }
  @media (max-width: 545px) {
    grid-gap: 40px;
    margin-top: 40px;
    & > div {
      min-height: 60px;
    }
  }
  @media (max-width: 375px) {
    & a {
      font-size: 16px;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  position: relative;
  & button {
    border-radius: 35px;
    font-size: 18px;
    line-height: 27px;
    font-weight: bold;
    margin-top: -12px;
  }
  @media (max-width: 768px) {
    margin-top: 58px;
  }

  @media (max-width: 575px) {
    & button {
      min-width: 100%;
      margin-left: 45px;
      margin-right: 45px;
    }
  }
  @media (max-width: 475px) {
    margin-top: 40px;
    & button {
      font-size: 14px;
      min-height: 50px;
    }
  }
`;
const NewPetsSection = ({ newPets }: IProps) => {
  const router = useRouter();
  return (
    <Container>
      <Wrapper>
        <Heading>
          \ <span>NEW!</span> /
        </Heading>
        <Title>新しくやってきた仔たち</Title>
        <Row>
          {newPets?.map((newPet) => (
            <PetCard
              key={newPet.id}
              id={newPet.id}
              href={`/pets/${newPet.id}`}
              image={newPet.main_image || "/default.png"}
              birth_date={
                newPet.birthday
                  ? moment(newPet.birthday).format("YYYY/MM/DD")
                  : ""
              }
              name={newPet.name}
              gender={newPet.gender}
              shop={newPet.shop}
              new={newPet.is_new === "1"}
            />
          ))}
        </Row>
        <PetLinks>
          <PetLink
            href="/find-a-pet?category=dog&all=true"
            image="/pets/dog.png"
            title="ワンちゃん一覧へ"
          />
          <PetLink
            href="/find-a-pet?category=cat&all=true"
            image="/pets/cat.png"
            title="ネコちゃん一覧へ"
          />
          <PetLink
            href="/find-a-pet?category=small_pets&all=true"
            image="/pets/small_animal.png"
            title="小動物一覧へ"
          />
          <PetLink
            href="/find-a-pet?category=fish&all=true"
            image="/pets/fish.png"
            title="観賞魚一覧へ"
          />
        </PetLinks>

        <ButtonWrapper>
          <Image src="/pets/group.png" height={179} width={330} />
          <Button
            onClick={() => router.push("/all-new-pets")}
            rounded
            type="primary"
            minwidth={500}
            minheight={70}
          >
            新しくやってきた仔たちを全て見る
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export { NewPetsSection };
