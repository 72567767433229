import styled from "styled-components";
import { theme } from "@project/shared";

interface TabPaneTitleProps {
  petType?: string;
  registeredNo?: number;
  imgSrc?: any;
  marginTop?: number;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  padding-left: 5px;

  @media (max-width: 520px) {
    padding-left: 2px;
  }
`;

const PetName = styled.p`
  font-size: 18px;
  line-height: 27px;
  color: ${theme.primary};
  margin-bottom: 0;
  font-weight: 600;

  @media (max-width: 520px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const RegisteredNo = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${theme.primary} !important;

  @media (max-width: 520px) {
    font-size: 20px;
    line-height: 30px;
  }
`;

const StyledImage = styled.img`
  height: 130px;
  margin-top: ${({ marginTop }: TabPaneTitleProps) => {
    return marginTop ? `${marginTop}px` : "53px";
  }};
  @media (max-width: 520px) {
    width: 60px;
    height: 78px;
  }
`;

export const TabPaneTitle: React.FC<TabPaneTitleProps> = ({
  petType,
  registeredNo,
  imgSrc,
  ...rest
}) => {
  return (
    <Wrapper>
      <StyledImage src={imgSrc} {...rest} />
      <TitleWrapper>
        <PetName>{petType}</PetName>
        <RegisteredNo>
          登録品種数<span>:</span>
          {registeredNo}件
        </RegisteredNo>
      </TitleWrapper>
    </Wrapper>
  );
};
