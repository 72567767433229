import styled from "styled-components";
import { PetFeatureTable } from "../PetFeatureTable";
import dynamic from "next/dynamic";
import { theme } from "@project/shared";

export const Container = styled.div`
  border: 4px solid ${theme.lightPrimary};
  padding: 51px 40px 49px 0px;
  margin-top: 60px;
  margin-bottom: 100px;
  border-radius: 10px;
  display: flex;
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;

  & > div:first-child {
    flex: 45%;
  }

  & > div:nth-child(2) {
    flex: 55%;
  }
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 1480px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
const PetFeatureGraph = dynamic(
  () => {
    return import("../PetFeatureGraph/index");
  },
  { ssr: false }
);

interface Props {
  features?: any;
  valuation?: any;
}

export const FeatureSection: React.FC<Props> = ({ features, valuation }) => {
  return (
    <Container>
      <PetFeatureGraph
        type="radar"
        featureLabels={valuation.featureValuationLabels}
        featureValues={valuation.featureValuationValues}
      />
      <PetFeatureTable features={features} />
    </Container>
  );
};
