import { TagV, theme, Typography } from "@project/shared";
import React from "react";
import Tick from "../../../../assets/tick.svg";
import Question from "../../../../assets/q.svg";
import Plus from "../../../../assets/plus.svg";
import OR from "../../../../assets/or.svg";
import styled, { keyframes } from "styled-components";
import { Tooltip } from "antd";

interface IRowStyled {
  last?: boolean;
}

interface IDescriptionPoint {
  typography?: any;
  color?: string;
}

const moveToBottom = keyframes`
 from {
   transform-origin: top;
   transform: scaleY(0);
}
 to {
    transform-origin: top;
    transform: scaleY(1);
 }
`;

const OuterWrapperStyled = styled.div`
  padding: 38px 40px 32px 40px;
  width: 100%;
  margin-bottom: 13px;
  border-radius: 10px;
  background: ${theme.primaryLight};
  animation: ${moveToBottom} 400ms;
  @media (max-width: 650px) {
    padding: 30px;
  }
  @media (max-width: 500px) {
    padding: 20px;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;
const WrapperStyled = styled.div`
  padding: 38px 40px 32px 40px;
  background: ${theme.base};
  border-radius: 10px;

  @media (max-width: 650px) {
    padding: 30px;
  }

  @media (max-width: 500px) {
    padding: 20px;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Box = styled.div`
  background-color: ${theme.primaryLight};
  height: 108px;
  border-radius: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 650px) {
    height: 90px;
  }
  @media (max-width: 400px) {
    & > p {
      font-size: 14px;
    }
  }
`;

const BottomWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  @media (max-width: 650px) {
    display: block;
  }

  & > div {
    display: flex;
    flex: 1;
  }
`;

const FirstBox = styled(Box)`
  margin-right: 12px;
  @media (max-width: 650px) {
    margin-right: 12px;
  }
  position: relative;
  & > svg {
    position: absolute;
    right: 0;
    transform: translateX(70%);
  }
`;
const ThirdBox = styled(Box)`
  margin-right: 12px;
  margin-left: 12px;
  @media (max-width: 650px) {
    margin-left: 0px;
    margin-top: 12px;
  }
  background-color: ${theme.lightGrey};
  position: relative;
  & > svg {
    position: absolute;
    right: 0;
    top: 10px;
    transform: translateX(70%);
  }
`;

const SecondBox = styled(Box)``;
const LastBox = styled(Box)`
  background-color: ${theme.lightGrey};
  @media (max-width: 650px) {
    margin-top: 12px;
  }
`;

const RowStyled = styled.div<IRowStyled>`
  display: flex;
  & > svg:first-child {
    margin-right: 10px;
  }
  & > div:nth-child(2) {
    margin-right: 20px;
    width: 85%;
  }
  & > svg:last-child {
    @media screen and (min-width: 751px) {
      align-self: center;
    }
  }
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: ${({ last }) =>
    last ? "none" : `1px solid ${theme.borderBtn}`};

  .questionmark {
    cursor: pointer;
  }
`;

const DescriptionList = styled.div`
  margin-top: 20px;
`;

const DescriptionPoint = styled.div<IDescriptionPoint>`
  @media (max-width: 768px) {
    white-space: pre-line;
  }
  @media (min-width: 1011px) {
    white-space: pre-line;
  }
  @media (min-width: 1348px) {
    white-space: unset;
  }
  margin-top: 10px;
  ${({ typography }: IDescriptionPoint) => {
    if (typography) {
      return typography;
    }
  }}
  color: ${({ color }: IDescriptionPoint) => {
    return color ? color : theme.black;
  }};
`;

const Row = ({ title, description = "", last = false, hover = "" }) => (
  <RowStyled last={last}>
    <Tick />
    <div>
      <Typography
        typography={theme.typography.PackTitle}
        color={theme.primaryText}
      >
        {title}
      </Typography>
      {description && (
        <Typography
          typography={theme.typography.PackDesc}
          color={theme.primaryText}
          topmargin="10px"
        >
          {description}
        </Typography>
      )}
    </div>
    {!last && (
      <Tooltip placement="top" title={hover}>
        <Question />
      </Tooltip>
    )}
  </RowStyled>
);

// pet pack besed on category & plan
interface PetPackProps {
  petPackList?: any;
  days?: string;
  support?: any;
  descriptionList?: string[];
}

export const PetPack: React.FC<PetPackProps> = ({
  petPackList,
  days,
  support,
  descriptionList,
}) => {
  return (
    <OuterWrapperStyled>
      <WrapperStyled>
        {petPackList?.map((petRow, petRowIndex) => (
          <Row
            title={petRow.items}
            key={`pet-row-${petRowIndex}`}
            description={petRow.description}
            last={petRow.last}
            hover={petRow.hover}
          />
        ))}

        <TagV
          text={days}
          bg={theme.primary}
          textColor={theme.base}
          bordercolor={theme.base}
          width="100%"
          textalign="center"
          padding="5px"
          margin="5px 0px 0px 0px"
          typography={theme.typography.Pack}
        />

        <BottomWrapper>
          <div>
            {support.sick && (
              <FirstBox>
                <Typography
                  color={theme.primaryText}
                  typography={theme.typography.Pack}
                >
                  病気
                </Typography>
                {support.accident && <Plus />}
              </FirstBox>
            )}

            {support.accident && (
              <SecondBox>
                <Typography
                  color={theme.primaryText}
                  typography={theme.typography.Pack}
                >
                  事故
                </Typography>
              </SecondBox>
            )}
          </div>
          <div>
            {support.alternetsupport && (
              <ThirdBox>
                <Typography
                  color={theme.primaryText}
                  typography={theme.typography.Pack}
                  center
                  breakspace
                >
                  {`代替 \n ペット対応`}
                </Typography>
                {support.insurance && <OR />}
              </ThirdBox>
            )}
            {support.insurance && (
              <LastBox>
                <Typography
                  color={theme.primaryText}
                  typography={theme.typography.Pack}
                >
                  返金対応
                </Typography>
              </LastBox>
            )}
          </div>
        </BottomWrapper>
        {descriptionList?.length > 0 ? (
          <DescriptionList>
            {descriptionList.map((description, index) => {
              return (
                <DescriptionPoint
                  key={`pet-description-${index}`}
                  typography={theme.typography.PackDesc}
                  color={theme.primaryText}
                >
                  {description}
                </DescriptionPoint>
              );
            })}
          </DescriptionList>
        ) : null}
      </WrapperStyled>
    </OuterWrapperStyled>
  );
};
