import React from "react";
import styled from "styled-components";
import { theme } from "@project/shared";

interface DottedTitleProps {
  title?: string;
  borderType?: string;
  marginTop?: string;
  marginBottom?: string;
  paddingTop?: string;
  paddingBottom?: string;
  color?: string;
  borderColor?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
}
const DottedTitle: React.FC<DottedTitleProps> = ({
  title,
  borderColor,
  borderType = "dashed",
  marginTop = "0px",
  paddingBottom = "0px",
  paddingTop = "0px",
  marginBottom = "0px",
  color = theme.primary,
  fontSize = "1.5rem",
  lineHeight = "2.25rem",
  fontWeight = "600",
}) => {
  const DottedTitleHeading = styled.h1`
    width: 100%;
    margin-top: ${marginTop};
    padding-top: ${paddingTop};
    padding-bottom: ${paddingBottom};
    font-size: ${fontSize};
    line-height: ${lineHeight};
    text-align: center;
    margin-bottom: ${marginBottom};
    color: ${color};
    border-top: 1px ${borderColor || color} ${borderType};
    border-bottom: 1px ${borderColor || color} ${borderType};
    font-weight: ${fontWeight};
  `;

  return <DottedTitleHeading>{title}</DottedTitleHeading>;
};
export { DottedTitle };
