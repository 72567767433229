import styled from "styled-components";
import { Typography, theme } from "@project/shared";
import FootIcon from "../../../assets/foot.svg";

interface ListTitleProps {
  title: string;
}

const Wrapper = styled.div`
  border-bottom: 1px solid ${theme.primary};
`;

const TitleWrapper = styled.div`
  display: flex;
  width: 200px;
  border-bottom: 4px solid ${theme.primary};
  padding: 10px 2px;
  margin-bottom: -2px;
  align-items: center;
`;

export const ListTitle: React.FC<ListTitleProps> = ({ title }) => {
  return (
    <Wrapper>
      <TitleWrapper>
        <FootIcon />
        <Typography padding="0 10px">{title}</Typography>
      </TitleWrapper>
    </Wrapper>
  );
};
