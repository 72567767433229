import React from "react";
import styled from "styled-components";
import { theme } from "@project/shared/src/theme";

interface HeaderWithHRProps {
  title: string;
  className?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Title = styled.div`
  ${theme.typography.BigHeading}
  color: ${theme.primary};
  border-bottom: 5px solid ${theme.primary};
  width: fit-content;
  padding-bottom: 13px;
  padding-right: 35px;
  font-weight: 600;
`;
const HorizontalLine = styled.div`
  border: 1px dashed ${theme.primary};
  margin-top: -3px;
`;

const HeaderWithHR: React.FC<HeaderWithHRProps> = ({ title, className }) => {
  return (
    <Wrapper className={className}>
      <Title>{title}</Title>
      <HorizontalLine />
    </Wrapper>
  );
};

export { HeaderWithHR };
