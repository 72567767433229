import styled from "styled-components";
import { theme } from "@project/shared";

interface BottomLinksProps {
  petType?: string;
  noOfRegPets?: number;
  imgSrc?: any;
  marginTop?: number;
}

const Wrapper = styled.div`
  border: 1px solid ${theme.primary};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  align-items: center;
  height: 80px;
  max-width: 600px;
  margin: auto;
  margin-bottom: 50px;
  width: 100%;
  @media (max-width: 520px) {
    padding: 0px;
  }
  @media (max-width: 375px) {
    justify-content: flex-start;
  }
`;

const DataDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  & p {
    white-space: nowrap;
    padding: 0 10px;
  }
  @media (max-width: 375px) {
    flex-direction: column;
  }
`;

const StyledImage = styled.img`
  z-index: 1;
  width: 100px;
  margin-top: ${({ marginTop }: BottomLinksProps) => {
    return marginTop ? `${marginTop}px` : "20px";
  }};
  @media (max-width: 520px) {
    width: 100px;
    margin-top: 37px;
  }
`;

const PetType = styled.p`
  color: ${theme.primary};
  font-size: 20px;
  margin: auto;
  font-weight: 600;
`;

const RegisteredNum = styled.p`
  color: ${theme.primary};
  font-size: 14px;
  margin: auto;

  & span {
    padding-left: 2px;
    padding-right: 1px;
  }
`;

export const PageBottomLinks: React.FC<BottomLinksProps> = ({
  petType,
  noOfRegPets,
  imgSrc,
  ...rest
}) => {
  return (
    <Wrapper>
      <StyledImage src={imgSrc} alt="" {...rest} />
      <DataDiv>
        <PetType>{petType}</PetType>
        <RegisteredNum>
          登録品種数<span>:</span>
          {noOfRegPets}件
        </RegisteredNum>
      </DataDiv>
    </Wrapper>
  );
};
