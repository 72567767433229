import { API, theme } from "@project/shared";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PictureBookCard } from "../../../molecules";

interface PictureBookType {
  info: string;
  image: string;
  title: string;
  count: string;
  link: string;
}

const Wrapper = styled.div`
  display: flex;
  margin: 0px auto 101px;
  position: relative;
  opacity: 0.9;
  border-radius: 10px;
  justify-content: space-between;
  padding: 47px 20px 52px 28px;
  background: ${theme.lightGrey1};
  max-width: 1480px;

  & button {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }
  @media (min-width: 1481px) {
    width: 98%;
  }
  @media (min-width: 1521px) {
    width: 100%;
  }
  @media (max-width: 1480px) {
    margin-left: 15px;
    margin-right: 15px;

    & button {
      width: 85%;
      min-width: auto;
    }
  }
  @media (max-width: 975px) {
    padding-top: 47px;
    padding-bottom: 52px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 51px 30px 76px;
    & button {
      margin-top: 20px;
      min-width: 300px;
      width: auto;
      min-height: 60px;
      font-size: 20px;
      line-height: 36px;
      border-radius: 35px;
    }
  }
  @media (max-width: 700px) {
    & button {
      min-width: 100%;
    }
  }
  @media (max-width: 525px) {
    & button {
      min-height: 50px;
      font-size: 16px;
      margin-top: 10px;
      line-height: 26px;
    }
  }
  @media (max-width: 475px) {
    padding: 40px 0px 40px;
    & button {
      min-height: 40px;
      font-size: 14px;
      margin-top: 10px;
    }
  }
`;

const Title = styled.p`
  letter-spacing: 5.74633px;
  font-size: 57px;
  margin-bottom: 18px;
  line-height: 57px;
  color: ${theme.lightBlack};
  @media (max-width: 1075px) and (min-width: 769px) {
    font-size: 36px;
  }
  @media (max-width: 475px) {
    font-size: 27px;
    margin-bottom: 0px;
    line-height: 38px;
  }
`;
const Description = styled.p`
  font-size: 14px;
  margin-bottom: 0px;
  line-height: 24px;
  white-space: break-spaces;
  font-weight: 400;
  text-align: center;
  color: ${theme.primaryText};
  @media (max-width: 1075px) and (min-width: 769px) {
    font-size: 12px;
  }
  @media (max-width: 475px) {
    font-size: 10px;
    line-height: 21px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60.6%;
  & > div {
    width: 48%;
  }
  & > div:first-child .title {
    color: #1589cf;
  }
  & > div:last-child .title {
    color: #ea4c8f;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    & > div {
      width: 100%;
    }
    & > div:first-child {
      margin-bottom: 83px;
    }
  }
  @media (max-width: 475px) {
    padding-left: 20px;
    padding-right: 20px;
    & > div:first-child {
      margin-bottom: 30px;
    }
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 326px;
  min-width: 338px;
  background: url("/pictureCard/bg.png") no-repeat center/contain;
  @media (max-width: 1255px) and (min-width: 769px) {
    margin-right: 30px;
  }
  @media (max-width: 1075px) {
    height: 300px;
    min-width: 250px;
  }
  @media (max-width: 475px) {
    height: auto;
    min-width: auto;
    margin-bottom: 30px;
  }
`;
const Ribbon = styled.div`
  color: ${theme.base};
  height: 35px;
  min-width: 238px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 17px;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  background: url("/pictureCard/ribbon.svg") no-repeat center/contain;
  @media (max-width: 1075px) {
    margin-top: 35px;
  }
  @media (max-width: 475px) {
    margin-bottom: 0px;
    min-width: auto;
    font-size: 10px;
    padding: 0px 8px;
  }
`;
const BottomImage = styled.div`
  position: absolute;
  top: 0px;
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  transform: translate(0%, -70%);
  left: 0%;
  & > div {
    max-width: 85% !important;
  }
`;

const PictureBookSection = () => {
  const [pictureBookInfo, setPictureBookInfo] = useState({} as any);
  useEffect(() => {
    getPictureBookCountInfo();
  }, []);

  const getPictureBookCountInfo = async () => {
    const response = await API.get("/consumer/picture-books/count");
    if (response && response.data) {
      setPictureBookInfo(response.data);
    }
  };

  const pictureBook = [
    {
      info: "ワンちゃん図鑑",
      image: "/pictureCard/dog.png",
      title: "Dog picture book",
      link: "/pet-dictionary?category=dog",
      count: pictureBookInfo.total_dog,
    },
    {
      info: "ネコちゃん図鑑",
      image: "/pictureCard/cat.png",
      title: "Cat picture book",
      link: "/pet-dictionary?category=cat",
      count: pictureBookInfo.total_cat,
    },
  ] as PictureBookType[];
  return (
    <Wrapper>
      <TitleContainer>
        <Ribbon>もっとペットのことを知ろう！</Ribbon>
        <Title>ペット図鑑</Title>
        <Description>
          {"ワンちゃん、ネコちゃんの種類や特徴など、\nペット図鑑でご紹介！"}
        </Description>
      </TitleContainer>

      <CardWrapper>
        {pictureBook &&
          pictureBook.map((book: PictureBookType, index: number) => (
            <PictureBookCard
              key={index}
              title={book.title}
              image={book.image}
              info={book.info}
              count={book.count}
              link={book.link}
            />
          ))}
      </CardWrapper>
      <BottomImage>
        <Image
          src="/columns/group.png"
          height={192}
          width={582}
          objectFit="contain"
          unoptimized={true}
        />
      </BottomImage>
    </Wrapper>
  );
};
export { PictureBookSection };
