import { Button, theme } from "@project/shared";
import React from "react";
import styled from "styled-components";
import { ReasonCard } from "../../../molecules";
import Link from "next/link";
import { useRouter } from "next/router";

interface ReasonType {
  title: string;
  image: string;
  description: string;
}
const Relative = styled.div`
  position: relative;
`;
const Container = styled.div`
  padding: 98px 0 250px;
  background: linear-gradient(
    182.4deg,
    #f4b42a 1.93%,
    #f4b42a 45.87%,
    #eb8814 98.07%
  );
  @media (max-width: 1480px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1480px;
  margin: auto;
  position: relative;
  z-index: 1;
  & button {
    margin: 79px auto 0px;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
  }
  @media (min-width: 1701px) {
    position: relative;
  }
  @media (min-width: 769px) {
    & .button-sm {
      display: none;
    }
  }
  @media (max-width: 768px) {
    & .button-lg {
      display: none;
    }
    & .button-sm {
      line-height: 1.5;
      min-height: 80px;
      border-radius: 50px;
      font-size: 28px;
      margin-top: 70px;
    }
  }
  @media (max-width: 575px) {
    & .button-sm {
      font-size: 26px;
      min-width: auto;
      max-width: 500px;
      width: 100%;
    }
  }
  @media (max-width: 475px) {
    & .button-sm {
      font-size: 16px;
      min-height: 60px;
      margin-top: 60px;
    }
  }
`;
const Pattern = styled.div`
  background-image: url("/pattern.png");
  height: 37px;
  position: absolute;
  width: 100%;
  top: -16px;
  @media (max-width: 768px) {
    top: -11px;
  }
`;

const Heading = styled.p`
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  margin-bottom: 23px;
  z-index: 1;
  color: ${theme.base};
`;
const Title = styled.p`
  font-size: 30px;
  line-height: 44px;
  text-align: center;
  font-weight: bold;
  z-index: 1;
  color: ${theme.base};
  margin-bottom: 93px;
  @media (max-width: 535px) {
    font-size: 26px;
    line-height: 36px;
  }
  @media (max-width: 475px) {
    font-size: 20px;
    margin-bottom: 90px;
  }
`;
const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 29.72%;
  }
  @media (max-width: 1300px) {
    & > div {
      width: 31%;
    }
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    justify-content: space-around;
    flex-wrap: wrap;
    & > div {
      width: 47%;
    }
    & > div:last-child {
      margin-top: 60px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    & > div {
      width: 100%;
      margin-bottom: 106px;
    }
    & > div:last-child {
      margin-bottom: 0px !important;
    }
  }
  @media (max-width: 475px) {
    & > div {
      margin-bottom: 70px;
    }
  }
`;
const Cat = styled.div`
  position: absolute;
  bottom: -69px;
  right: 0px;
  background: url("/reasons/cat.png") no-repeat right center/contain;
  height: 292px;
  width: 606px;
  @media (max-width: 1175px) {
    height: 289px;
    width: 427px;
  }
  @media (max-width: 475px) {
    height: 250px;
    width: 100%;
  }
  @media (max-width: 395px) {
    height: 200px;
  }
`;
const Dog = styled.div`
  position: absolute;
  top: -125px;
  background: url("/reasons/dog.png") no-repeat right center/contain;
  height: 347px;
  width: 270px;
  left: 31px;
  @media (max-width: 768px) {
    left: -63px;
  }
  @media (max-width: 475px) {
    left: -30px;
    height: 217px;
    width: 160px;
  }
`;

const ReasonSection = () => {
  const router = useRouter();
  const reasons = [
    {
      image: "/reasons/map.svg",
      title: "全国に122店舗",
      description:
        "関東・中部・近畿・中国・四国・九州の、ホームセンターや大型量販店にチェーン展開しております。",
    },
    {
      image: "/reasons/group.png",
      title: "トータルサポートできるお店",
      description:
        "「高品質、低価格、ハイサービス」をモットーにペットと人との素敵なライフスタイル創りをお手伝いいたします。",
    },
    {
      image: "/reasons/variety.svg",
      title: "ペットの種類が豊富",
      description:
        "いぬ、ねこ、うさぎ、ハムスターなどの小動物、熱帯魚や金魚などの観賞魚、などのペットから選ぶことができます。",
    },
  ] as ReasonType[];
  return (
    <Relative>
      <Pattern />
      <Container>
        <Wrapper>
          <Dog />
          <Heading>REASON</Heading>
          <Title>コーワペッツ・ペッツフレンズが選ばれる理由</Title>
          <CardWrapper>
            {reasons &&
              reasons.map((reason: ReasonType, index: any) => (
                <ReasonCard
                  key={index}
                  image={reason.image || "/default.png"}
                  index={`0${index + 1}`}
                  title={reason.title}
                  description={reason.description}
                />
              ))}
          </CardWrapper>
          <Button
            rounded
            color={theme.primary}
            background={theme.base}
            minheight={60}
            className="button-lg"
            minwidth={400}
          >
            <Link href="/about-us">
              <a>コーワペッツ・ペッツフレンズについて</a>
            </Link>
          </Button>
          <Button
            onClick={() => router.push("/about-us")}
            rounded
            className="button-sm"
            color={theme.primary}
            background={theme.base}
            minheight={100}
            minwidth={460}
          >
            コーワペッツ・
            <br />
            ペッツフレンズについて
          </Button>
        </Wrapper>
        <Cat />
      </Container>
    </Relative>
  );
};
export { ReasonSection };
