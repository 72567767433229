import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import {
  TagV,
  theme,
  Button,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from "@project/shared";

import VideoIcon from "../../../assets/video.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import { PetContext } from "../context/petContext";
import { Typography } from "antd";
const { Paragraph } = Typography;
interface CardProps {
  name?: string;
  shop?: string;
  gender?: string;
  category?: string;
  birth_date?: string;
  image?: any;
  new?: boolean;
  id?: number;
  withPrice?: boolean;
  href?: string;
  price?: number;
  units?: number;
  movie_url?: string;
  showdetail?: boolean;
}

const Wrapper = styled.div`
  padding: 20px 18px;
  position: relative;
  background: ${theme.base};
  border-radius: 10px;
  box-shadow: -1.22465e-15px 20px 20px rgba(4, 0, 0, 0.0784314);
  @media screen and (max-width: 578px) {
    padding: 10px 8px;
    width: 100%;
  }
`;
const PetImage = styled.div`
  cursor: pointer;
  & > div {
    height: 235px;
    width: 100%;
  }
  img {
    border-radius: 8px;
  }
`;
const SpeciesName = styled(Paragraph)`
  font-size: 14px;
  display: block;
  line-height: 1;
  font-weight: bold;
  color: ${theme.selectArrow};
`;
const CannotAccess = styled(Paragraph)`
  font-size: 14px;
  display: block;
  line-height: 1;
  font-weight: bold;
  color: #e89f4b;
`;

const Born = styled.strong`
  color: #959595;
  font-size: 14px;
  line-height: 24px;
`;
const InfoWrapper = styled.div`
  margin-top: 10px;
  & a:hover strong {
    color: ${theme.link};
  }
`;
const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
const AddFavouriteWrapper = styled.div`
  cursor: pointer;
  height: 34px;
  width: 34px;
  @media screen and (max-width: 568px) {
    margin-bottom: 10px;
  }
`;
const NewImage = styled.div`
  position: absolute;
  top: 3px;
  width: 60px;
  height: 60px;
  z-index: 1;
  right: 3px;
  @media screen and (max-width: 690px) {
    width: 40px;
    height: 40px;
  }
  & img {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const VideoButton = styled(Button)`
  font-style: normal;
  span {
    padding-left: 4px;
  }
  svg {
    width: 13px;
    height: 11px;
  }
  @media screen and (max-width: 578px) {
    margin-top: 10px;
  }
`;
const Price = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  margin-top: 17px;
`;
const Number = styled.strong`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${theme.darkGrey};
`;
const PetCardWithPrice: React.FC<CardProps> = (props: CardProps) => {
  const FAV_ITEMS_KEY = "fav";
  const { showdetail = true } = props;
  const { t } = useTranslation();
  const router = useRouter();
  const [getList, setList] = useState([] as any);
  const { setFavItemListCount } = useContext(PetContext);
  const priceFormat = Intl.NumberFormat();
  useEffect(() => {
    const favItems = getItemFromLocalStorage(FAV_ITEMS_KEY) || [];
    setList(favItems.map((item) => item?.id));
  }, []);
  const addToFavouriteHandler = () => {
    const favItems = getItemFromLocalStorage(FAV_ITEMS_KEY) || [];
    const favIds = favItems.map((item) => item.id);
    if (favIds.indexOf(props.id) === -1) {
      const favItem = {
        id: props.id,
      };
      favItems.push(favItem);
      saveItemToLocalStorage(FAV_ITEMS_KEY, favItems);
      setList(favItems.map((item) => item.id));
    } else {
      const filteredItems = favItems.filter((item) => item.id != props.id);
      saveItemToLocalStorage(FAV_ITEMS_KEY, filteredItems);
      setList(filteredItems.map((item) => item.id));
    }
  };
  useEffect(() => {
    setFavItemListCount(getList.length);
  }, [getList.length]);
  const handlePetDetails = () => {
    if (showdetail) {
      router
        .push("/pets/[id]", `/pets/${props.id}`, {
          shallow: true,
        })
        .then(() => window.scrollTo(0, 0));
    }
  };
  return (
    <Wrapper>
      {props.new === true && (
        <NewImage>
          <img src={"/NEW.png"} />
        </NewImage>
      )}
      <PetImage className="image" onClick={handlePetDetails}>
        <Image
          src={props.image || "/default.png"}
          width={240}
          height={240}
          objectFit="cover"
          unoptimized={true}
        />
      </PetImage>
      <InfoWrapper>
        {props.href ? (
          <Link href={props.href}>
            <a>
              <SpeciesName
                ellipsis={{ rows: 1, expandable: true, symbol: " " }}
              >
                {t(`${props.name}`)}
              </SpeciesName>
              {props.shop && props.shop !== "" && (
                <SpeciesName
                  ellipsis={{ rows: 1, expandable: true, symbol: " " }}
                >
                  {t(`${props.shop}`)}
                </SpeciesName>
              )}
            </a>
          </Link>
        ) : (
          <>
            <SpeciesName ellipsis={{ rows: 1, expandable: true, symbol: " " }}>
              {t(`${props.name}`)}
            </SpeciesName>
            {props.shop && props.shop !== "" && (
              <SpeciesName
                ellipsis={{ rows: 1, expandable: true, symbol: " " }}
              >
                {t(`${props.shop}`)}
              </SpeciesName>
            )}
            {!showdetail && (
              <CannotAccess>
                {t(`Data was removed by the administrator`)}
              </CannotAccess>
            )}
          </>
        )}
        {props.birth_date !== "" && (
          <Born>{t(`${props.birth_date}生まれ`)}</Born>
        )}
        {props.withPrice && (
          <ButtonWrapper>
            {props.gender === "boy" ? (
              <TagV text="♂ 男の仔" />
            ) : props.gender === "girl" ? (
              <TagV
                text="♀️ 女の仔"
                textColor="#d65082"
                bordercolor="#d65082"
              />
            ) : (
              <div />
            )}

            {props.movie_url && (
              <VideoButton
                minheight={28}
                minwidth={105}
                fontsize={13}
                color="#FFFFFF"
                borderradius={14}
                background="#959595"
                htmlType="button"
                icon={<VideoIcon />}
              >
                {t("動画あり")}
              </VideoButton>
            )}
          </ButtonWrapper>
        )}
        {props.withPrice && props.price > 0 && (
          <Price>
            {t(`¥${priceFormat.format(props.price)}`)}
            <small style={{ fontSize: "60%" }}>
              {t(`（税込 ¥${priceFormat.format(Math.floor(props.price))}）`)}
            </small>
          </Price>
        )}
        <CardFooter>
          {props.withPrice ? (
            <Number>{t(`No.${props.units}`)}</Number>
          ) : props.gender === "boy" ? (
            <TagV text="♂ 男の仔" />
          ) : props.gender === "girl" ? (
            <TagV text="♀️ 女の仔" textColor="#d65082" bordercolor="#d65082" />
          ) : (
            <div />
          )}

          <AddFavouriteWrapper onClick={addToFavouriteHandler}>
            {getList.indexOf(props.id) !== -1 ? (
              <Image
                src={"/fav.png"}
                layout="responsive"
                width={34}
                height={34}
                unoptimized={true}
              />
            ) : (
              <Image
                src={"/notfav.png"}
                layout="responsive"
                width={34}
                height={34}
                unoptimized={true}
              />
            )}
          </AddFavouriteWrapper>
        </CardFooter>
      </InfoWrapper>
    </Wrapper>
  );
};

export { PetCardWithPrice };
