import React from "react";

import { Drawer, Collapse } from "antd";
import styled from "styled-components";
import NextLink from "next/link";

import MinusOutlined from "../../../assets/icons/minus.svg";
import CaretRightOutlined from "../../../assets/icons/right_outlined.svg";
import PlusOutlined from "../../../assets/icons/plus.svg";

import { PetLink, theme } from "@project/shared";

interface Props {
  visible: boolean;
  setVisible: any;
}

const PetLinkWrapper = styled.div`
  margin-top: 38px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 145px;
  a {
    padding: 0px 20px;
  }
  @media (max-width: 595px) {
    margin-bottom: 78px;
  }
  @media (max-width: 515px) {
    margin-bottom: 68px;
  }
  @media (max-width: 475px) {
    margin-bottom: 47px;
  }
  @media (max-width: 420px) {
    margin-top: 15px;
  }
`;

const Container = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
  & .ant-drawer-mask {
    background-color: transparent !important;
  }
`;
const CardRow = styled.div`
  display: flex;
  flex-direction: column;
  & > div:last-child > div {
    margin-bottom: 0px !important;
  }
  @media screen and (max-width: 769px) {
    display: flex;
    justify-content: center;
    margin: 0px !important;
  }
`;

const PetLinkContainer = styled.div`
  max-width: 305px;
  & > div {
    max-width: 500px;
    margin: auto;
    margin-bottom: 73px;
  }
  @media (max-width: 595px) {
    & > div {
      max-width: 90%;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 420px) {
    & > div {
      margin-bottom: 32px;
      min-height: 70px;
    }
    & > div a {
      font-size: 14px;
      padding: 0px;
    }
  }
  @media (max-width: 330px) {
    & > div a {
      font-size: 13px;
    }
  }
  @media (min-width: 769px) {
    display: none;
  }
  @media screen and (max-width: 769px) {
    max-width: 100%;
  }
  @media screen and (max-width: 578px) {
    a {
      font-size: 18px;
    }
  }
`;
const MobileContainer = styled.div`
  margin-top: 90px;
  @media (max-width: 425px) {
    margin-top: 45px;
  }
  & a {
    color: ${theme.black};
    min-height: 100px;
    padding-left: 25px;
    font-size: 28px;
    font-weight: 500;
    justify-content: space-between;
    padding-right: 25px;
    border-bottom: 1px solid ${theme.grey2};
    display: flex;
    align-items: center;
    @media (max-width: 670px) {
      font-size: 16px;
    }
  }
  & .ant-collapse {
    border: none;
    border-top: 1px solid ${theme.grey2};
    & > a {
      background-color: ${theme.lightGrey};
      @media (max-width: 670px) {
        font-size: 16px;
      }
    }
  }
  & .ant-collapse-header {
    min-height: 100px;
    font-size: 28px;
    @media (max-width: 670px) {
      font-size: 16px;
    }
    font-weight: 500;
    background-color: ${theme.lightGrey};
    padding: 0px !important;
    padding-left: 25px !important;
    display: flex;
    align-items: center;
    & svg {
      top: 42px !important;
      right: 25px !important;
    }
  }
  & .ant-collapse-content-box {
    display: flex;
    flex-direction: column;
    padding: 0px;
    & a {
      font-weight: 400;
      padding-left: 45px;
    }
    & > a:last-child {
      border-bottom: none !important;
    }
  }

  & > a {
    background-color: ${theme.lightGrey};
  }
  & a svg {
    font-size: 12px;
  }
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 670px) {
    & a {
      font-size: 16px;
    }
    & .ant-collapse-header {
      font-size: 16px;
    }
  }
  @media (max-width: 560px) {
    & a,
    .ant-collapse-header {
      font-size: 16px;
      min-height: 60px;
      & svg {
        top: 25px !important;
        height: 16px;
        width: 16px;
      }
    }
  }
  @media (max-width: 415px) {
    & a,
    .ant-collapse-header {
      font-size: 14px !important;
      min-height: 55px;
      padding-left: 20px !important;
      padding-right: 20px;
      & svg {
        top: 21px !important;
        right: 20px !important;
      }
    }
  }
`;

const BottomNavigationMenu: React.FC<Props> = ({ visible, setVisible }) => {
  const Link = ({ href, children }) => (
    <NextLink href={href}>
      <a onClick={() => setVisible(false)} href={href}>
        {children}
      </a>
    </NextLink>
  );

  const PetLinkInfo = [
    {
      href: `/find-a-pet?category=dog`,
      text: "ワンちゃん",
      image: "/dog.png",
    },
    {
      href: `/find-a-pet?category=cat`,
      text: "ネコちゃん",
      image: "/smallcat.png",
    },
    {
      href: `/find-a-pet?category=small_pets`,
      text: "小動物",
      image: "/mouse.png",
    },
    {
      href: `/find-a-pet?category=fish`,
      text: "観賞魚",
      image: "/fish.png",
    },
  ];

  const { Panel } = Collapse;

  return (
    <Container>
      <Drawer
        placement={"top"}
        closable={false}
        visible={visible}
        height="100%"
        onClose={() => setVisible(false)}
        mask={false}
        zIndex={1041}
      >
        <PetLinkWrapper>
          <CardRow>
            {PetLinkInfo.map((info, index) => (
              <PetLinkContainer key={index} onClick={() => setVisible(false)}>
                <PetLink
                  image={info.image}
                  href={info.href}
                  title={info.text}
                />
              </PetLinkContainer>
            ))}
          </CardRow>
          <MobileContainer>
            <Collapse
              expandIconPosition="right"
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
            >
              <Link href={"/search-store"}>
                ペットショップを探す
                <CaretRightOutlined />
              </Link>
              <Panel header="トリミングサロンを探す" key={1}>
                <Link href={"/search-trimming"}>
                  トリミングサロン一覧
                  <CaretRightOutlined />
                </Link>
              </Panel>
            </Collapse>
            <Link href={"/about-us"}>
              コーワペッツ・ペッツフレンズについて
              <CaretRightOutlined />
            </Link>
            <Link href={"/voices"}>
              お客様の声
              <CaretRightOutlined />
            </Link>
            <Link href={"/column-list"}>
              コラム
              <CaretRightOutlined />
            </Link>
            <Link href={"/pet-dictionary"}>
              ペット図鑑
              <CaretRightOutlined />
            </Link>
          </MobileContainer>
        </PetLinkWrapper>
      </Drawer>
    </Container>
  );
};

export default BottomNavigationMenu;
