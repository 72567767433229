import { Typography } from "@project/shared";
import { theme } from "@project/shared/src/theme";
import React from "react";
import ReactPlayer from "react-player/youtube";
import styled from "styled-components";

interface IPetMovie {
  movie_url?: string;
}

const ReactPlayerStyled = styled(ReactPlayer)`
  height: 310px !important;
`;

const PetMovie: React.FC<IPetMovie> = ({ movie_url }) => {
  return (
    <>
      {movie_url && (
        <>
          <Typography
            color={theme.primary}
            fontSize="32px"
            lineHeight="32px"
            fontfamily="'Bebas Neue', cursive;"
            letterSpacing="1.6px"
            bottommargin="12px"
          >
            MOVIE
          </Typography>
          <ReactPlayerStyled width={"100%"} url={movie_url} />
        </>
      )}
    </>
  );
};

export { PetMovie };
