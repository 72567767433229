import { TagV, theme, Typography } from "@project/shared";
import React from "react";
import styled from "styled-components";
import { default as Pen } from "../../../../assets/pencil.svg";

interface IProps {
  shopComment?: string;
}

const WrapperStyled = styled.div`
  border: 2px solid ${theme.secondary};
  white-space: break-spaces;
  border-radius: 10px;
  padding: 46px 0px 46px 0px;
  text-align: center;
  position: relative;

  & > span:first-child {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
`;

const TagStyled = styled(TagV)`
  @media (max-width: 410px) {
    padding: 4px 37px 4px 17px;
  }
  & > span:last-child {
    @media (max-width: 410px) {
      font-size: 16px;
    }
  }
`;

const PenStyle = styled(Pen)`
  margin-right: 10px;
`;

export const PetStuff: React.FC<IProps> = ({ shopComment }) => {
  return (
    <WrapperStyled>
      <TagStyled
        bg={theme.secondary}
        textColor={theme.base}
        bordercolor={theme.base}
        text="STAFFからのコメント"
        icon={<PenStyle />}
        typography={theme.typography.Stuff}
        padding="4px 57px 4px 27px "
        radius="22px"
      />
      <Typography
        textAlign="left"
        padding="0px 0px 0px 31px"
        fontSize="14px"
        lineHeight="21px"
      >
        {shopComment}
      </Typography>
    </WrapperStyled>
  );
};
