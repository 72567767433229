import { TagV, Typography } from "@project/shared";
import { theme } from "@project/shared/src/theme";
import React from "react";
import styled from "styled-components";
import moment from "moment";
import NumberFormat from "react-number-format";
const WrapperStyled = styled.div``;

interface IPetDescription {
  description?: any;
  furColorDetails?: any;
}

const RowStyled = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.borderBtn};
  padding: 18px 0px;
  & > :first-child {
    flex: 3;
    @media screen and (max-width: ${theme.size}) {
      flex: 4;
    }
  }
  & > :last-child {
    flex: 6;
  }

  &:first-child {
    border-top: 1px solid ${theme.borderBtn};
  }
`;

const PetDescription: React.FC<IPetDescription> = ({
  description,
  furColorDetails,
}) => {
  const todayDate: any = new Date();
  const bday: any = new Date(description.誕生日);
  const diff = todayDate - bday;
  const diffDays = diff / (1000 * 3600 * 24);
  const diffMonths = diffDays / 30;
  const differentUI = (row) => {
    switch (row) {
      case "毛色（系統）":
        return (
          <div>
            <Typography
              color={theme.primaryText}
              typography={theme.typography.Desc}
              display="inline-block"
              margin="0px 20px 0px 0px"
            >
              {description[row]}
            </Typography>
            <Typography
              color={theme.lightText}
              typography={theme.typography.Desc}
              display="inline-block"
              margin="0px 20px 0px 0px"
            >
              {furColorDetails}
            </Typography>
          </div>
        );
      case "性別":
        return description[row] === "男の仔" ? (
          <TagV text={`♂ ${description[row]}`} />
        ) : (
          <TagV
            text={`♀️ ${description[row]}`}
            textColor="#d65082"
            bordercolor="#d65082"
          />
        );
      case "誕生日":
        return (
          <>
            <Typography
              color={theme.primaryText}
              typography={theme.typography.Desc}
              display="inline-block"
              margin="0px 20px 0px 0px"
            >
              {description[row] &&
                moment(description[row]).format("YYYY/MM/DD")}
              頃
            </Typography>
            {diffMonths > 3 ? (
              ""
            ) : (
              <TagV
                textColor={theme.base}
                bg={theme.secondary}
                bordercolor={theme.base}
                text="最近生まれた仔"
              />
            )}
          </>
        );

      case "生体価格":
        if (description[row] === "-") {
          return (
            <Typography
              color={theme.primaryText}
              typography={theme.typography.Desc}
              display="inline-block"
              bold
            >
              店舗にお問い合わせ下さい
            </Typography>
          );
        }
        return (
          <>
            <Typography
              color={theme.primaryText}
              typography={theme.typography.BigHeading}
              display="inline-block"
              bold
            >
              <NumberFormat
                thousandSeparator={true}
                displayType={"text"}
                value={description[row]}
                prefix={"¥"}
              />
            </Typography>
            <span>
              （税込{" "}
              <NumberFormat
                thousandSeparator={true}
                displayType={"text"}
                value={Math.floor(description[row] * 1.1)}
                prefix={"¥"}
              />
              ）
            </span>
          </>
        );

      default:
        return (
          <Typography
            color={theme.primaryText}
            typography={theme.typography.Desc}
            display="inline-block"
            margin="0px 20px 0px 0px"
          >
            {description[row]}
          </Typography>
        );
    }
  };
  return (
    <WrapperStyled>
      {Object.keys(description)
        ?.filter((row) => description[row])
        .map((row, index) => (
          <RowStyled key={index}>
            <Typography
              color={theme.primaryText}
              typography={theme.typography.Desc}
            >
              {row}
            </Typography>
            <div>{differentUI(row)}</div>
          </RowStyled>
        ))}
    </WrapperStyled>
  );
};

export { PetDescription };
