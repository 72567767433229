import { Button, theme } from "@project/shared";
import Router from "next/router";
import React from "react";
import styled from "styled-components";

interface Props {
  info: string;
  image: string;
  title: string;
  count: string;
  link: string;
}
const Card = styled.div`
  background: ${theme.base};
  box-shadow: -1.22465e-15px 20px 20px rgba(4, 0, 0, 0.0784314);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  & p {
    margin-bottom: 0px;
  }
  & > div:first-child {
    width: 100%;
    height: 150px;
    border-radius: 10px;
  }
  & button {
    margin: auto;
  }
`;
const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  line-height: 40px;
`;
const Info = styled.p`
  color: ${theme.primaryText};
  font-size: 18px;
  line-height: 40px;
  font-weight: bold;
`;
const Count = styled.p`
  color: ${theme.lightText};
  font-size: 12px;
  line-height: 40px;
  font-weight: 500;
`;
const StyledImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  object-position: center;
`;

const PictureBookCard = ({ image, title, info, count, link }: Props) => {
  return (
    <Card>
      <StyledImage src={image} />
      <Title className="title">{title} </Title>
      <Info>{info}</Info>
      <Count>登録件数：{count}件</Count>
      <Button
        rounded
        type="primary"
        minheight={42}
        minwidth={250}
        onClick={() => Router.push(link)}
      >
        詳しくはこちら
      </Button>
    </Card>
  );
};

export { PictureBookCard };
