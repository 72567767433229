import { theme } from "@project/shared";
import Image from "next/image";
import React from "react";
import styled from "styled-components";

interface Props {
  image: string;
  title: string;
  description: string;
  index: string;
}
const Card = styled.div`
  background: ${theme.base};
  box-shadow: -1.22465e-15px 20px 20px rgba(235, 96, 20, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 45px 22px 30px 18px;
  position: relative;
`;
const Title = styled.p`
  margin-bottom: 16px;
  color: ${theme.primary};
  font-size: 22px;
  margin-top: 5px;
  font-weight: bold;
  line-height: 33px;
`;
const Description = styled.p`
  margin-bottom: 0px;
  color: ${theme.primaryText};
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
`;
const Top = styled.div`
  position: absolute;
  top: -34px;
  z-index: 1;
`;
const Paw = styled.div`
  background-image: url("/paw.svg");
  height: 51px;
  width: 53px;
  display: flex;
  font-weight: bold;
  font-size: 17px;
  padding-top: 19px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  color: ${theme.base};
  background-repeat: no-repeat;
`;
const ReasonCard = ({ image, title, description, index }: Props) => {
  return (
    <Card>
      <Image src={image} height={155} width={268} objectFit="contain" unoptimized={true} />
      <Top>
        <Paw>{index}</Paw>
        <Image
          src="/reasons/reason.svg"
          height={14}
          width={56}
          objectFit="contain"
          unoptimized={true}
        />
      </Top>
      <Title>{title} </Title>
      <Description>{description}</Description>
    </Card>
  );
};

export { ReasonCard };
